
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, FRONTEND_URL, IMAGE_URL } from '../../config/axios';
import { ToastContainer, toast } from "react-toastify";
import { EditAppraisalDetailsByEmployee, UploadAppraisalDetailsByEmployee } from '../AppraisalManagement/AppraisalComponents';
import { handleAllError } from '../CustomFunctions';


const Userdetailsmain = ({ email, emp_code }) => {
  const [formData, setFormData] = useState({})
  const [appData, setAppData] = useState(null)
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getUSerDetails = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`);
      setFormData(res.data);

    } catch (err) {
      // alert(err.message);

      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getApprasalList = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/quickAppresalgetbyemp/${emp_code}/`);
      setAppData(res.data);

      if (res.status === 200) {
        // alert("res200");
        // setAppData(res.data);
      }
      else if (res.status === 400) {
        // alert("res400");
        setAppData(null);
      }
      // else {
      //   alert(res);
      // }
    } catch (err) {
      console.log("err")
      console.log(err)
      setAppData(null);
      // handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  useEffect(() => {
    getUSerDetails();
    getApprasalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [formDataa, setFormDataa] = useState({
    profilepic: null,
  });

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setFormDataa({
      profilepic: file,
    });

  };

  const uploadFile = async (file) => {
    const formDataa = new FormData();

    const symbols = '!@#$%^&*()_-+=';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const now = new Date();
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
    const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
    const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
    let randomCode = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      randomCode += allChars[randomIndex];
    }
    const originalFile = file;
    const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
    const customFile = new File([originalFile], customFileName, { type: originalFile.type });
    formDataa.append('profilepic', customFile);

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, formDataa, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        await getUSerDetails;
        window.location.reload();

      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleLogoClear = () => {
    setFormData({
      ...formData,
      profilepic: null,
    });
    setFormDataa({
      ...formDataa,
      profilepic: null,
    });
  };

  // console.log("appData")
  // console.log(appData)

  return (
    <div className='user_main-outer'>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className='user_main'>
        <div className='user_inner'>
          <div className='user_inner_style'>


            {formData.profilepic ? (
              <img className='user_img' src={`${IMAGE_URL}${formData.profilepic}`} alt='user_img' />
            ) : (

              <>
                <input
                  type="file"
                  name="profilepic"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="file-input"
                  style={{ width: '200px' }}
                // size="5"
                />


                {formDataa.profilepic ? (
                  <>
                    <span className="file-clearr file-action-button" onClick={handleLogoClear}>
                      Clear
                    </span>
                    <span>/</span>
                    <span className="file-clear file-action-button" onClick={(e) => uploadFile(formDataa.profilepic)}>
                      Submit
                    </span>
                    <br />
                    <img
                      src={URL.createObjectURL(formDataa.profilepic)}
                      alt="Logo Preview"
                      className="user_img_trial"
                    />

                  </>
                ) : (
                  <>
                    <br />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cloud-upload"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                      />
                    </svg>
                    <span className="file-name" style={{ marginLeft: "20px" }}>
                      Upload Profile Pic
                    </span>
                  </>
                )}
              </>



            )}
            <div className={formData.profilepic ? "user_img_box" : "user_img_boxx"}>{formData.designation_name}</div>
          </div>

          <div className='user_del'>
            <div className='flex-row' style={{ paddingRight: " 5vw", justifyContent: "space-between", flexWrap: 'wrap' }}>
              <div>
                <h1 className='user_name   font-weight500 font-size-heading'>{formData.name}</h1></div>

              {buffer ?
                <>
                  <div className="spinner-small"></div>
                  {/* <div className="spinner-z"></div> */}
                </> :
                <div>
                  <a href={`${FRONTEND_URL}/personaldetailss/${formData.user_detail}/`} className="employee-details-edit" target="_blank" title="Edit Profile Details" rel="noopener noreferrer">
                    <button style={{ display: 'flex', alignItems: "center" }} className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        className='employee-detail-edit-svg'
                        fill='#FFFFFF '
                        id="edit">
                        <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z">
                        </path>
                      </svg> Details
                    </button>
                  </a>

                  {/* need for later */}
                  {/* {sessionStorage.getItem("company_id") === "910ee6d1-9fd8-4da1-a9cb-369b1ffecd7c" || sessionStorage.getItem("emp_code") === "CIPL10007" ? */}
                  {/* {sessionStorage.getItem("company_id") === "910ee6d1-9fd8-4da1-a9cb-369b1ffecd7c" && !formData.appraisal_form ? */}
                  {/* {!formData.appraisal_form ? */}
                  {/* {appData === null ? */}

                  {/* {!formData.appraisal_form ? */}
                  {appData === null ?
                    <UploadAppraisalDetailsByEmployee />
                    : null
                  }
                  {appData !== null && appData.hod_update_datetime === null && appData.admin_update_datetime === null ?
                    <EditAppraisalDetailsByEmployee i={appData} />
                    : null
                  }
                </div>}
            </div>
            <p className='user_desg  font-weight500  font-size-heading'>{formData.designation_name} at {formData.division_name}</p>
            <div className='user_inner_del font-weight400  font-size-subheading  width-60vw'>
              <div className='user_inner_box'>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                  <path d="M7 4C6.38193 4 5.77775 4.18328 5.26384 4.52666C4.74994 4.87004 4.3494 5.3581 4.11288 5.92911C3.87635 6.50013 3.81447 7.12847 3.93505 7.73466C4.05562 8.34085 4.35325 8.89767 4.79029 9.33471C5.22733 9.77175 5.78415 10.0694 6.39034 10.19C6.99653 10.3105 7.62487 10.2486 8.19589 10.0121C8.7669 9.7756 9.25496 9.37506 9.59834 8.86116C9.94172 8.34725 10.125 7.74307 10.125 7.125C10.125 6.2962 9.79576 5.50134 9.20971 4.91529C8.62366 4.32924 7.8288 4 7 4ZM7 9C6.62916 9 6.26665 8.89003 5.95831 8.68401C5.64996 8.47798 5.40964 8.18514 5.26773 7.84253C5.12581 7.49992 5.08868 7.12292 5.16103 6.75921C5.23337 6.39549 5.41195 6.0614 5.67417 5.79917C5.9364 5.53695 6.27049 5.35837 6.63421 5.28603C6.99792 5.21368 7.37492 5.25081 7.71753 5.39273C8.06014 5.53464 8.35298 5.77496 8.55901 6.08331C8.76503 6.39165 8.875 6.75416 8.875 7.125C8.875 7.62228 8.67746 8.09919 8.32583 8.45083C7.97419 8.80246 7.49728 9 7 9ZM7 0.25C5.17727 0.252068 3.42979 0.97706 2.14092 2.26592C0.85206 3.55479 0.127068 5.30227 0.125 7.125C0.125 9.57812 1.25859 12.1781 3.40625 14.6445C4.37127 15.759 5.45739 16.7626 6.64453 17.6367C6.74962 17.7103 6.87482 17.7498 7.00312 17.7498C7.13143 17.7498 7.25663 17.7103 7.36172 17.6367C8.54668 16.7623 9.63069 15.7587 10.5938 14.6445C12.7383 12.1781 13.875 9.57812 13.875 7.125C13.8729 5.30227 13.1479 3.55479 11.8591 2.26592C10.5702 0.97706 8.82273 0.252068 7 0.25ZM7 16.3438C5.70859 15.3281 1.375 11.5977 1.375 7.125C1.375 5.63316 1.96763 4.20242 3.02252 3.14752C4.07742 2.09263 5.50816 1.5 7 1.5C8.49184 1.5 9.92258 2.09263 10.9775 3.14752C12.0324 4.20242 12.625 5.63316 12.625 7.125C12.625 11.5961 8.29141 15.3281 7 16.3438Z" fill="#707070" />
                </svg>
                <p>{formData.location_name}</p>
              </div>
              <div className='user_inner_box'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17.5 3.75H2.5C2.33424 3.75 2.17527 3.81585 2.05806 3.93306C1.94085 4.05027 1.875 4.20924 1.875 4.375V15C1.875 15.3315 2.0067 15.6495 2.24112 15.8839C2.47554 16.1183 2.79348 16.25 3.125 16.25H16.875C17.2065 16.25 17.5245 16.1183 17.7589 15.8839C17.9933 15.6495 18.125 15.3315 18.125 15V4.375C18.125 4.20924 18.0592 4.05027 17.9419 3.93306C17.8247 3.81585 17.6658 3.75 17.5 3.75ZM15.893 5L10 10.4023L4.10703 5H15.893ZM16.875 15H3.125V5.79609L9.57734 11.7109C9.69265 11.8168 9.84348 11.8755 10 11.8755C10.1565 11.8755 10.3074 11.8168 10.4227 11.7109L16.875 5.79609V15Z" fill="#707070" />
                </svg>
                <a href={`mailto:${formData.user_detail}`}><p style={{ textDecoration: "underline" }}>{formData.user_detail}</p></a>
              </div>
              <div className='user_inner_box'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17.3727 12.3798L13.6922 10.7306L13.682 10.7259C13.491 10.6441 13.2825 10.6114 13.0756 10.6304C12.8687 10.6495 12.6698 10.7199 12.4969 10.8352C12.4765 10.8487 12.457 10.8633 12.4383 10.879L10.5367 12.5001C9.33204 11.9149 8.08829 10.6806 7.50313 9.49149L9.12657 7.56102C9.14219 7.54149 9.15704 7.52196 9.1711 7.50087C9.28394 7.32845 9.35239 7.13081 9.37039 6.92553C9.38838 6.72026 9.35534 6.51372 9.27423 6.3243V6.31493L7.62032 2.62821C7.51309 2.38076 7.3287 2.17463 7.09468 2.04058C6.86067 1.90654 6.58958 1.85177 6.32188 1.88446C5.26326 2.02376 4.29155 2.54366 3.58824 3.34704C2.88492 4.15042 2.49809 5.18234 2.50001 6.25009C2.50001 12.4532 7.54688 17.5001 13.75 17.5001C14.8177 17.502 15.8497 17.1152 16.6531 16.4119C17.4564 15.7085 17.9763 14.7368 18.1156 13.6782C18.1484 13.4106 18.0937 13.1396 17.9598 12.9056C17.8259 12.6716 17.62 12.4871 17.3727 12.3798ZM13.75 16.2501C11.0987 16.2472 8.55687 15.1927 6.68214 13.318C4.8074 11.4432 3.7529 8.90136 3.75001 6.25009C3.74707 5.48719 4.02192 4.7493 4.52324 4.17423C5.02456 3.59916 5.71806 3.22623 6.47423 3.12509C6.47392 3.1282 6.47392 3.13134 6.47423 3.13446L8.11485 6.80634L6.50001 8.73915C6.48362 8.75801 6.46873 8.77812 6.45548 8.7993C6.33791 8.97971 6.26894 9.18743 6.25525 9.40232C6.24157 9.61722 6.28362 9.83201 6.37735 10.0259C7.08516 11.4735 8.54376 12.9212 10.007 13.6282C10.2023 13.7211 10.4184 13.7617 10.634 13.7461C10.8497 13.7304 11.0576 13.6592 11.2375 13.5391C11.2576 13.5256 11.2769 13.511 11.2953 13.4954L13.1945 11.8751L16.8664 13.5196C16.8664 13.5196 16.8727 13.5196 16.875 13.5196C16.7751 14.2769 16.4027 14.9718 15.8275 15.4743C15.2524 15.9769 14.5138 16.2527 13.75 16.2501Z" fill="#707070" />
                </svg>
                {/* <p>{formData.contact_number}</p> */}
                <a href={`tel:+91${formData.contact_number}`}><p style={{ textDecoration: "underline" }}>{formData.contact_number}</p></a>

              </div>

            </div>
          </div>
        </div>
        <div className='user_inner2 font-weight400 '>
          <div>
            <h1 className='font-size-text'>Job Title</h1>
            <p>{formData.designation_name}</p>
          </div>
          <div>
            <h1 className='font-size-text'>Department</h1>
            <p>{formData.department_name}</p>
          </div>
          <div>
            <h1 className='font-size-text'>Business Unit</h1>
            <p>{formData.division_name}</p>
          </div>
          <div>
            <h1 className='font-size-text'>Reporting Person</h1>
            <p>{formData.rh_name}
              {/* <br />{formData.reporting_head} */}
            </p>
          </div>
          <div>
            <h1 className='font-size-text'>Emp no</h1>
            <p>{formData.emp_code}</p>
          </div>
        </div>

      </div>

    </div >
  )
}

export default Userdetailsmain;
