import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { RaiseATicket, RaiseTicketWithDocument, TicketChatboxModal } from "./TicketsComponents";
import { Eye, Filter, SearchBig } from "../AllSvg";
import { formatDate, formatDateTime, formattedDateLong } from "../Date";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import { handleAllError } from "../CustomFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";

const RaisedTickets = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [show, setShow] = useState(false);
    const [indexRow, setIndexRow] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setIndexRow(e);
    };
    // *********** Employee Details Api Start ***********
    const [ticketsList, setTicketsList] = useState([]);
    const [filteredTicketsList, setFilteredTicketsList] = useState([]);

    const getAllTicketsList = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/ticket/ticket/`
            );
            setTicketsList(res.data);

            const uniqueDayStatus = [
                ...new Set(res.data.map((entry) => entry.status)),
            ];
            setStatus_list(uniqueDayStatus);
            const uniquePriorityStatus = [
                ...new Set(res.data.map((entry) => entry.priority)),
            ];
            setPriority_status(uniquePriorityStatus);
        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };
    useEffect(() => {
        getAllTicketsList();
    }, []);
    // *********** Employee Details Api End ***********


    const [startdate, setStartDate] = useState(``);

    const [enddate, setEndDate] = useState(``);

    // *********** Filter Logic ***********

    // const [selectedStatus, setSelectedStatus] = useState([
    //     "open",
    //     "acceptance",
    //     "completed",
    //     "close",
    //     "pending",
    //     "inprocess",
    // ]);
    const [status_list, setStatus_list] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(status_list);
    const [asignee, setAsignee] = useState("");
    const [asigning, setAsigning] = useState("");
    const [priority, setPriority] = useState("");
    const [priority_status, setPriority_status] = useState([]);

    function handlePriority(value) {
        if (value == 1) {
            return (
                <button className="priority-button font-weight600 Color-tab-red">
                    High
                </button>
            );
        } else if (value == 2) {
            return (
                <button className="priority-button font-weight600 Color-tab-yellow">
                    Medium
                </button>
            );

        } else if (value == 3) {
            return (
                <button className="priority-button font-weight600 Color-tab-blue">
                    Low
                </button>
            );
        } else return <td className="align-center"></td>;
    }

    const handleSearch = () => {
        let filteredData = ticketsList;


        if (selectedStatus.length > 0) {
            filteredData = filteredData.filter((employee) =>
                selectedStatus.includes(employee.status)
            );
        }
        if (selectedStatus.length === 0) {
            filteredData = filteredData.filter((employee) =>
                // ["pending", "inprocess", "completed"].includes(employee.status)
                status_list.includes(employee.status)
            );
        }

        if (priority !== "") {
            if (priority === "1") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            } else if (priority === "2") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            } else if (priority === "3") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            }
        }
        if (asignee) {
            const lowercaseSelectedName = asignee.toLowerCase();
            filteredData = filteredData.filter((employee) => {
                const assigneeNameMatch = employee.assigned_by_name
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const assigneeEmailMatch = employee.assigned_by
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const title = employee.title
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const description = employee.description
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                return (
                    assigneeNameMatch || assigneeEmailMatch || title || description
                );
            });
        }

        if (startdate !== "") {
            filteredData = filteredData.filter(
                (employee) =>
                    employee.due_date >= startdate && employee.assign_date >= startdate
            );
        }
        if (enddate !== "") {
            filteredData = filteredData.filter(
                (employee) =>
                    employee.due_date <= enddate && employee.assign_date <= enddate
            );
        }

        setFilteredTicketsList(filteredData);
    };

    useEffect(() => {
        handleSearch();
    }, [
        selectedStatus,
        ticketsList,
        asignee,
        startdate,
        enddate,
        asigning,
        priority,
    ]);

    const statusName = [
        {
            status: "open",
            name: "Open",
        },
        {
            status: "acceptance",
            name: "Acceptance",
        },
        {
            status: "pending",
            name: "Pending",
        },
        {
            status: "inprocess",
            name: "In Process",
        },
        {
            status: "completed",
            name: "Completed",
        },
        {
            status: "close",
            name: "Close",
        },
    ];

    const options = statusName.map((e) => ({
        value: e.status,
        label: e.name,
    }));

    const handleChangecc = (selected) => {
        const multipleperson =
            selected.length > 0 ? selected.map((item) => item.value) : "";
        setSelectedStatus(multipleperson);
    };

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const ticketUrl = "/" + urlParts[3] + "/ticket/";
    const ViewTicketDetails = (i) => {
        navigate(ticketUrl + i + '/', { state: { i } });
    };


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="content-tabs">
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <input
                                className="attendance-input-field width-10vw   date-field"
                                placeholder="From Date"
                                type="date"
                                value={startdate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <input
                                className="attendance-input-field width-10vw   date-field"
                                placeholder="To Date"
                                type="date"
                                value={enddate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Filter />
                            <Select
                                className="attendance-input-field width-15vw  "
                                width="200px"
                                options={options}
                                placeholder="Status"
                                isMulti
                                onChange={handleChangecc}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        color: '#707070',
                                        outline: 'none',
                                        border: 'none',
                                        // padding: '0.6rem 0.5rem',
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            border: 'none',
                                        },
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: '#707070',
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: '#707070',
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        filter: 'invert(50%)',
                                        cursor: 'pointer',
                                        left: '9.5rem',
                                        width: '20px',
                                        height: '20px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999, // Ensure dropdown menu appears above other elements
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: state.isSelected ? '#2576bc' : '#707070',
                                        backgroundColor: state.isSelected ? 'rgba(37,118,188,0.1)' : 'white',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(5, 205, 153, 0.1)',
                                        },
                                    }),
                                }}
                            />

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                <option value="">Priority</option>
                                {priority_status
                                    .sort((a, b) => a - b)
                                    .map((prior) => (
                                        <option value={prior}>
                                            {prior === "1"
                                                ? "High"
                                                : prior === "2"
                                                    ? "Medium"
                                                    : prior === "3"
                                                        ? "Low"
                                                        : "All"}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div-svg">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-15vw  "
                                placeholder="By Task, Emp & Name"
                                type="text"
                                value={asignee}
                                onChange={(e) => setAsignee(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="btn-cont">

                    </div>
                </div>

                <div className="table-css-white-background">
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr no.</th>
                                <th className="align-center width-5vw">Request By</th>
                                <th className="align-center width-5vw">Title</th>
                                <th className="align-center width-15vw">Task</th>
                                <th className="align-center width-5vw">Assigned Date</th>
                                <th className="align-center width-5vw">Due date</th>
                                <th className="align-center">Chat History</th>
                                <th className="align-center">Priorty</th>
                                <th className="align-center">Status</th>
                                <th className="align-center">Action</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div> //buffering logic
                        ) : (
                            <tbody>
                                {filteredTicketsList
                                    .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                                    .sort((a, b) => a.priority - b.priority)
                                    .map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="align-left">{index + 1}</td>
                                                <td className="align-center">
                                                    {i.assigned_by_name} <br /> {i.assigned_by}
                                                </td>
                                                <td className="align-center">{i.title}</td>
                                                <td className="align-left width-15vw">
                                                    {" "}
                                                    <div dangerouslySetInnerHTML={createMarkup(i.description)} />
                                                </td>
                                                <td className="align-center">
                                                    {formatDate(i.assign_date)}{" "}
                                                </td>
                                                <td className="align-center">
                                                    {formatDate(i.due_date)}
                                                </td>

                                                <td className="align-center">
                                                    {i.remarks?.map((edata, index) => {
                                                        return (
                                                            <div>
                                                                By : {edata.name}
                                                                <br />
                                                                On: {formatDate(edata.created_at)}
                                                                <br />
                                                                {edata.remark_text}
                                                            </div>
                                                        );
                                                    })}
                                                    <TicketChatboxModal
                                                        i={i.id}
                                                        getAllTasks={getAllTicketsList}
                                                    />
                                                </td>

                                                <td className="align-center">
                                                    {" "}
                                                    {i.priority ? handlePriority(i.priority) : '-'}
                                                </td>
                                                <td className="align-center ">{i.status}</td>
                                                <td className="align-center">
                                                    <button
                                                        className=""
                                                        title="View Ticket Details"
                                                        onClick={(e) => ViewTicketDetails(i.id)}
                                                    >
                                                        <Eye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const MyTickets = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [show, setShow] = useState(false);
    const [indexRow, setIndexRow] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setIndexRow(e);
    };
    // *********** Employee Details Api Start ***********
    const [ticketsList, setTicketsList] = useState([]);
    const [filteredTicketsList, setFilteredTicketsList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("pending");

    const getAllTicketsList = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/ticket/ticket-assigned-by/${sessionStorage.getItem("email")}/${selectedStatus}/`
            );
            setTicketsList(res.data);

            const uniqueDayStatus = [
                ...new Set(res.data.map((entry) => entry.status)),
            ];
            setStatus_list(uniqueDayStatus);
            const uniquePriorityStatus = [
                ...new Set(res.data.map((entry) => entry.priority)),
            ];
            setPriority_status(uniquePriorityStatus);
        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };
    useEffect(() => {
        getAllTicketsList();
    }, [selectedStatus]);

    // *********** Employee Details Api End ***********

    const [startdate, setStartDate] = useState(``);

    const [enddate, setEndDate] = useState(``);

    // *********** Filter Logic ***********

    const [status_list, setStatus_list] = useState([]);
    const [asignee, setAsignee] = useState("");
    const [asigning, setAsigning] = useState("");
    const [priority, setPriority] = useState("");
    const [priority_status, setPriority_status] = useState([]);

    function handlePriority(value) {
        if (value == 1) {
            return (
                <button className="priority-button font-weight600 Color-tab-red">
                    High
                </button>
            );
        } else if (value == 2) {
            return (
                <button className="priority-button font-weight600 Color-tab-yellow">
                    Medium
                </button>
            );

        } else if (value == 3) {
            return (
                <button className="priority-button font-weight600 Color-tab-blue">
                    Low
                </button>
            );
        } else return <td className="align-center"></td>;
    }

    const handleSearch = () => {
        let filteredData = ticketsList;

        if (priority !== "") {
            if (priority === "1") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            } else if (priority === "2") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            } else if (priority === "3") {
                filteredData = filteredData.filter(
                    (employee) => employee.priority === priority
                );
            }
        }
        if (asignee) {
            const lowercaseSelectedName = asignee.toLowerCase();
            filteredData = filteredData.filter((employee) => {
                const assigneeNameMatch = employee.assigned_by_name
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const assigneeEmailMatch = employee.assigned_by
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const title = employee.title
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                const description = employee.description
                    .toLowerCase()
                    .includes(lowercaseSelectedName);

                return (
                    assigneeNameMatch || assigneeEmailMatch || title || description
                );
            });
        }

        if (startdate !== "") {
            filteredData = filteredData.filter(
                (employee) =>
                    employee.due_date >= startdate && employee.assign_date >= startdate
            );
        }
        if (enddate !== "") {
            filteredData = filteredData.filter(
                (employee) =>
                    employee.due_date <= enddate && employee.assign_date <= enddate
            );
        }

        setFilteredTicketsList(filteredData);
    };

    useEffect(() => {
        handleSearch();
    }, [
        selectedStatus,
        ticketsList,
        asignee,
        startdate,
        enddate,
        asigning,
        priority,
    ]);

    const statusName = [
        {
            status: "open",
            name: "Open",
        },
        {
            status: "acceptance",
            name: "Acceptance",
        },
        {
            status: "pending",
            name: "Pending",
        },
        {
            status: "inprocess",
            name: "In Process",
        },
        {
            status: "completed",
            name: "Completed",
        },
        {
            status: "close",
            name: "Close",
        },
    ];

    const options = statusName.map((e) => ({
        value: e.status,
        label: e.name,
    }));

    const handleChangecc = (selected) => {
        const multipleperson =
            selected.length > 0 ? selected.map((item) => item.value) : "";
        setSelectedStatus(multipleperson);
    };

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const ticketUrl = "/" + urlParts[3] + "/ticket/";
    const ViewTicketDetails = (i) => {
        navigate(ticketUrl + i + '/', { state: { i } });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="content-tabs">
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <input
                                className="attendance-input-field width-10vw   date-field"
                                placeholder="From Date"
                                type="date"
                                value={startdate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <input
                                className="attendance-input-field width-10vw   date-field"
                                placeholder="To Date"
                                type="date"
                                value={enddate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>
                                {status_list.map((status) => (
                                    <option value={status}>{status}</option>
                                ))}
                            </select>
                            <hr className="field-cont-hr" />

                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                <option value="">Priority</option>
                                {priority_status
                                    .sort((a, b) => a - b)
                                    .map((prior) => (
                                        <option value={prior}>
                                            {prior === "1"
                                                ? "High"
                                                : prior === "2"
                                                    ? "Medium"
                                                    : prior === "3"
                                                        ? "Low"
                                                        : "All"}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div-svg">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-15vw  "
                                placeholder="By Task, Emp & Name"
                                type="text"
                                value={asignee}
                                onChange={(e) => setAsignee(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="btn-cont">
                        <RaiseATicket getTicketList={getAllTicketsList} />
                        {/* <RaiseTicketWithDocument getTicketList={getAllTicketsList} /> */}
                    </div>
                </div>

                <div className="table-css-white-background">
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr no.</th>
                                <th className="align-center width-5vw">Request By</th>
                                <th className="align-center width-5vw">Title</th>
                                <th className="align-center width-15vw">Task</th>
                                <th className="align-center width-5vw">Assigned Date</th>
                                <th className="align-center width-5vw">Due date</th>
                                <th className="align-center">Chat History</th>
                                <th className="align-center">Priorty</th>
                                <th className="align-center">Status</th>
                                <th className="align-center">Action</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div> //buffering logic
                        ) : (
                            <tbody>
                                {filteredTicketsList
                                    .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                                    .sort((a, b) => a.priority - b.priority)
                                    .map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="align-left">{index + 1}</td>
                                                <td className="align-center">
                                                    {i.assigned_by_name} <br /> {i.assigned_by}
                                                </td>
                                                <td className="align-center">{i.title}</td>
                                                <td className="align-left width-15vw">
                                                    {" "}
                                                    <div dangerouslySetInnerHTML={createMarkup(i.description)} />
                                                </td>
                                                <td className="align-center">
                                                    {formatDate(i.assign_date)}{" "}
                                                </td>
                                                <td className="align-center">
                                                    {formatDate(i.due_date)}
                                                </td>

                                                <td className="align-center">
                                                    {i.remarks?.map((edata, index) => {
                                                        return (
                                                            <div>
                                                                By : {edata.name}
                                                                <br />
                                                                On: {formatDate(edata.created_at)}
                                                                <br />
                                                                {edata.remark_text}
                                                            </div>
                                                        );
                                                    })}
                                                    <TicketChatboxModal
                                                        i={i.id}
                                                        getAllTasks={getAllTicketsList}
                                                    />
                                                </td>

                                                <td className="align-center">
                                                    {" "}
                                                    {i.priority ? handlePriority(i.priority) : '-'}
                                                </td>
                                                <td className="align-center ">{i.status}</td>
                                                <td className="align-center">
                                                    <button
                                                        className=""
                                                        title="View Ticket Details"
                                                        onClick={(e) => ViewTicketDetails(i.id)}
                                                    >
                                                        <Eye />
                                                    </button>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const ViewTicket = () => {

    const navigate = useNavigate()
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const backUrl = "/" + urlParts[3] + `/ticketSystem`;

    const location = useLocation();
    const { i } = location.state;

    console.log("i")
    console.log(i)
    const backroute = () => {
        navigate(backUrl)
    }

    const [buffer, setBuffering] = useState(true); //buffering logic
    const [ticket, setTicketData] = useState([]);
    const [ticketChat, setTicketChat] = useState([]);

    const getTicketDetails = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const ticket = await axios.get(`${BASE_URL}/ticket/ticket/${i}/`);
            setTicketData(ticket.data);
            const chat = await axios.get(`${BASE_URL}/ticket/ticketChat-by-ticket/${i}/`);
            setTicketChat(chat.data);
        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getTicketDetails();
    }, []);


    const getTicketChat = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {


        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    return (
        <>
            <Dashboardnavbarcopy name={"Ticket Details"} url="Ticket Managment" />
            <div className="content-tabs">
                <>

                    <div className="">
                        <button className="model-button model-button-black font-weight500" onClick={backroute}>Back</button>
                        <br /><br />

                        <div className="table-css-white-background ">
                            <div className="flex-row justify-between">
                                <h2>
                                    Ticket ID: {ticket.id}
                                </h2>
                                <h4>
                                    <span
                                        className={`priority-button2  behavior`}
                                        data-status={ticket.status}
                                    >
                                        {ticket.status}
                                    </span>
                                </h4>
                            </div>
                            <div class="ticket-date">
                                Date Submitted:{" "}
                                {formattedDateLong(ticket.assign_date)}
                            </div>
                            <div class="ticket-desc-header">Title: "{ticket.title}"</div>
                            <hr />
                            <div className="ticket-desc">
                                <div class="ticket-desc-header">Description of Issue</div>
                                <p>{ticket.description}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="table-css-white-background ">
                            <div className="flex-row justify-between">
                                <h3>
                                    Chat History:
                                </h3>
                                <TicketChatboxModal i={i} />
                            </div>

                            <ul className="chatbox">
                                {buffer ? (
                                    <div className="spinner"></div>
                                ) : (
                                    ticketChat.length === 0 ?
                                        <div className='align-center justify-center ' style={{ alignSelf: 'center' }}>
                                            - No Chat Historys 🪹 -
                                        </div>
                                        :
                                        <>
                                            {ticketChat
                                                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                                                .map((i, index) => (
                                                    <>
                                                        {i.sender == sessionStorage.getItem("email") ?
                                                            <>
                                                                <li className="chat chat-reply">
                                                                    <p>{i.message}</p>
                                                                </li>
                                                                <p className="chat-reply-timestamp font-size-label">{formatDateTime(i.timestamp).full}</p>
                                                            </>
                                                            :
                                                            <>
                                                                <li className="chat chat-incoming">
                                                                    <p>{i.message}</p>
                                                                </li>
                                                                <p className="chat-incoming-timestamp font-size-label">{formatDateTime(i.timestamp).full}</p>
                                                            </>
                                                        }
                                                    </>
                                                ))}

                                        </>
                                )
                                }
                            </ul>
                        </div>
                    </div>

                </>
            </div>
        </>
    );
};

export {
    RaisedTickets,
    MyTickets,
    ViewTicket,
};
