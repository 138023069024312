// ************ ==== >>>> Page <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword.jsx";
import Resetpassword from "../pages/website/Resetpassword.jsx";

// ************ ==== >>>> Testing Components <<<< ==== ************//

import Dashboardnavbarcopy from "../layout/Dashboardnavbarcopy.jsx";


import Calander from "../components/dashboard-comp/Calaneder.jsx";
import Informationcomponent from "../components/dashboard-comp/Informationcomponent.jsx";

import CriticalIssues from "../components/CriticalIssues.jsx";

import Accountdashboard from "../pages/Accountdashboard/Accountdashboard.jsx";


// ************ ==== >>>> Components <<<< ==== ************//
import Userdetailsmain from "../components/UserDetails/Userdetailsmain.jsx";
import Userdetailscrud from "../components/UserDetails/UserDetailsPermission_role.jsx";

// ************ ==== >>>> Interview Form <<<< ==== ************//

import UserDetails from "../components/UserDetails/UserDetails.jsx";

import { ApplicantviewRound1, ApplicantviewRound2, ApplicantviewRound3, ApplicantviewRound4, Applicantview, AppliedPage } from "../components/career/update_details/ApplicantviewRounds.jsx";



import Workforcemangement from "../components/workforcemanagement/Workforcemangement.jsx";
import TlWorkfm from "../components/workforcemanagement/TlWorkfm.jsx";




// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import SiteManagementDashboard from "../pages/sitedashboard/SiteDashboard.jsx";
import SiteDashboard from "../pages/sitedashboard/Main/dashboard.jsx";


// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmployeeDashboard from "../pages/employeedashboard/Employeedashboard.jsx";
import EmpDashboard from "../pages/employeedashboard/Main/Dashboard.jsx";

// ************ ==== >>>> Tl Dashboard <<<< ==== ************//
import TlDashboardd from "../pages/teamleaddashboard/TlDashboard.jsx";
import TlDashboard from "../pages/teamleaddashboard/Main/dashboard";

// ************ ==== >>>> Hod Dashboard <<<< ==== ************//
import HodDashboardd from "../pages/hoddashboard/HodDashboard";
import HodDashboard from "../pages/hoddashboard/Main/dashboard";
import HOD from "../components/workforcemanagement/HOD.jsx";

// ************ ==== >>>> Hr Dashboard <<<< ==== ************//
import HrDashboardd from "../pages/hrdashboard/HrDashboard";

import HrDashboard from "../pages/hrdashboard/Main/dashboard";

// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AdminDashboardd from "../pages/admindashboard/AdminDashboard";
import AdminDashboard from "../pages/admindashboard/Main/dashboard.jsx";
import Career from "../components/career/Career.jsx";
import Setting from "../components/settings/Settings.jsx";

// ************ ==== >>>> IT Dashboard <<<< ==== ************//
import ItDashboardMain from "../pages/ITdashboard/IT_Dashboard.jsx";
import ItDashboard from "../pages/ITdashboard/Main/Dashboard.jsx";

import PerformanceManagement from "../components/workforcemanagement/PerformanceManagement.jsx";
import Settingsit from "../components/settings/Settingit.jsx";
import LetterHeadPE from "../components/LetterHead/LetterHeadPE.jsx";

import LetterHeadPr from "../components/LetterHead/LetterHeadPR.jsx";
import LetterHeadPrP from "../components/LetterHead/LetterHeadPRP.jsx";
import Letter from "../components/LetterHead/Letter.jsx";
import LetterFormat from "../components/LetterHeadadmin/Letter.jsx";
import LetterHeadE from "../components/LetterHead/LetterHeadE.jsx";
import AppraisalSheet from "../components/AppraisalManagement/AppraisalSheet.jsx";




import SalarySlip from "../components/salryslip/SalarySlip.jsx";
import PayrollManagement from "../components/PayrollManagement/PayrollManagement.jsx";
import RequisitionManagement from "../components/RequisitionManagement/RequisitionManagement.jsx";
import PrintSalarySlipByEmployee from "../components/salryslip/SalarySlipbyEmployee";
import { CompletePage, PersonaldetailsAccountdetails, PersonaldetailsDocumentdetails, PersonaldetailsEducationdetails, PersonaldetailsEmergencycontactdetails, PersonaldetailsProfile, Personaldetailsworkexprience } from "../components/career/PersonaldetailsProfile.jsx";
import { DocumentdetailsPage, EducationDetail, Personaldetails, Positiondetails, Workexprience } from "../components/career/PersonaldetailsCareer.jsx";
import StationaryManagement from "../components/StationaryManagement/StationaryManagement.jsx";
import SiteManagement from "../components/SiteManagement/SiteManagement.jsx";
import WebsiteManagement from "../components/WebsiteManagement/WebsiteManagement.jsx";
import { ProjectAllDetails, ProjectConsultantDetails, ProjectContractorDetails, SiteProjectBillDetails } from "../components/SiteManagement/SiteProjectManagement.jsx";
import ImprestExpenseManagement from "../components/ImprestExpenseManagement/ImprestExpenseManagement.jsx";
import ExpenseSlipByExpense from "../components/ImprestExpenseManagement/ExpenseSheet/ExpenseSlipByExpense.jsx";
import ExpenseSlipByRequest from "../components/ImprestExpenseManagement/ExpenseSheet/ExpenseSlipByRequest.jsx";
import { AccountsImprestExpenseRequestMangement } from "../components/ImprestExpenseManagement/ImprestExpenseRequestManagement.jsx";
import { AdminRequestsManagementTab, AttendanceAndSelfRequestManagement, HRRequestsManagementTab, TeamRequestsManagementTab } from "../components/RequestManagement/RequestManagementComponents.jsx";
import AdminMenu from "../pages/admindashboard/AdminMenu.jsx";
import PrintDynamicSalarySlipData from "../components/salryslip/SalarySlipDynamic.jsx";
import TicketsManagement from "../components/TicketsManagement/TicketsManagement.jsx";
import { ViewTicket } from "../components/TicketsManagement/TicketsTables.jsx";
import PrintDynamicUpcomingSalarySlip from "../components/salryslip/SalarySlipDynamicIncoming.jsx";


export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },

      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },

      {
        path: "calander",
        component: <Calander />,
      },
      {
        path: "informationcomponent",
        component: <Informationcomponent />,
      },
      {
        path: "dashboardnavbarcopy",
        component: <Dashboardnavbarcopy />,
      },
      {
        path: "personaldetails/:id/",
        component: <Personaldetails />,
      },
      {
        path: "personaldetails/:id/:email/",
        component: <Personaldetails />,
      },
      {
        path: "positiondetails/:id/:email/",
        component: <Positiondetails />,
      },
      {
        path: "educationdetail/:id/:email/",
        component: <EducationDetail />,
      },

      {
        path: "workexprience/:id/:email/",
        component: <Workexprience />,
      },
      {
        path: "document/:id/:email/",
        component: <DocumentdetailsPage />,
      },
      {
        path: "jobApplied/",
        component: <AppliedPage />,
      },
      {
        path: "processComplete/",
        component: <CompletePage />,
      },

      {
        path: "applicantview/:id/:email/",
        component: <Applicantview />,
      },
      {
        path: "applicantview/round1/:id/:email/",
        component: <ApplicantviewRound1 />,
      },
      {
        path: "applicantview/round2/:id/:email/",
        component: <ApplicantviewRound2 />,
      },
      {
        path: "applicantview/round3/:id/:email/",
        component: <ApplicantviewRound3 />,
      },
      {
        path: "applicantview/round4/:id/:email/",
        component: <ApplicantviewRound4 />,
      },

      {
        path: "letterheadPE",
        component: <LetterHeadPE />,
      },
      {
        path: "letterheadPR",
        component: <LetterHeadPr />,
      },
      {
        path: "letterheadPRP",
        component: <LetterHeadPrP />,
      },
      {
        path: "letterheadIE",
        component: <LetterHeadE />,
      },

      {
        path: "letteradmin/:id/:emp/",
        component: <LetterFormat />,
      },

      {
        path: "salarySlip",
        component: <SalarySlip />,
      },
      {
        path: "salarySlip/:email/",
        component: <SalarySlip />,
      },

      {
        path: "salarySlip/:year/:month/:id/",
        component: <SalarySlip />,
      },
      {
        path: "requestExpenseSlip/:id/",
        component: <ExpenseSlipByRequest />,
      },

      {
        path: "dynamicSalarySlip",
        component: <PrintDynamicSalarySlipData />,
      },
      {
        path: "upcomingSalarySlip",
        component: <PrintDynamicUpcomingSalarySlip />,
      },
    ],
  },
  {
    role: "",
    isProtected: false,
    defaultRoute: "jd/jdmain",
    nestedRoutes: [
      {
        routes: [
          {
            path: "userdetails",
            component: <Userdetailsmain />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "educationDetailss/:email/",
            component: <PersonaldetailsEducationdetails />,
          },
          {
            path: "workexperiencee/:email/",
            component: <Personaldetailsworkexprience />,
          },
          {
            path: "emergencycontacts/:email/",
            component: <PersonaldetailsEmergencycontactdetails />,
          },
          {
            path: "accountdetailss/:email/",
            component: <PersonaldetailsAccountdetails />,
          },
          {
            path: "documentdetailss/:email/",
            component: <PersonaldetailsDocumentdetails />,
          },


          {
            path: "userdetail",
            component: <UserDetails />,
          },

          {
            path: "userdetail/letterhead",
            component: <LetterHeadPE />,
          },
          {
            path: "userdetail/letterheadPR",
            component: <LetterHeadPr />,
          },
          {
            path: "userdetail/letterheadPRP",
            component: <LetterHeadPrP />,
          },
        ],
        // path: "jd",
        // component: <Jobapplicantdashboardd />,
      },
    ],
  },

  {
    role: "SiteManagement",
    isProtected: true,
    defaultRoute: "site/siteDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <SiteDashboard />,
          },
          {
            path: "siteDashboard",
            component: <SiteDashboard />,
          },

          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },


          {
            path: "userdetail",
            component: <UserDetails />,
          },

          {
            path: "empDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },

          {
            path: "siteManagement",
            component: <SiteManagement />,
          },


        ],
        path: "site",
        component: <SiteManagementDashboard />,
      },
    ],
  },

  {
    role: "Employee",
    isProtected: true,
    defaultRoute: "ed/empDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "empDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          // {
          //   path: "requestManagement",
          //   component: <RequestManagement />,
          // },

          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "ticketSystem",
            component: <TicketsManagement />,
          },
          {
            path: "ticket/:id/",
            component: <ViewTicket />,
          },
          {
            path: "empDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "ed",
        component: <EmployeeDashboard />,
      },
    ],
  },

  {
    role: "ReportingHead",
    isProtected: true,
    defaultRoute: "tl/tlDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <TlDashboard />,
          },
          {
            path: "tlDashboard",
            component: <TlDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "wfm",
            component: <TlWorkfm />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },

          {
            path: "tlDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "RequisitionManagement",
            component: <RequisitionManagement />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "tl",
        component: <TlDashboardd />,
      },
    ],
  },

  {
    role: "Account",
    isProtected: true,
    defaultRoute: "acc/account",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "account",
            component: <EmpDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "imprestExpenseRequestManagement",
            component: <AccountsImprestExpenseRequestMangement />,
          },

          {
            path: "empDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "acc",
        component: <Accountdashboard />,
      },
    ],
  },

  {
    role: "IT",
    isProtected: true,
    defaultRoute: "it/itDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <ItDashboard />,
          },
          {
            path: "itDashboard",
            component: <ItDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },

          {
            path: "itDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
        ],
        path: "it",
        component: <ItDashboardMain />,
      },
    ],
  },


  {
    role: "HOD",
    isProtected: true,
    defaultRoute: "hod/hodDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <HodDashboard />,
          },
          {
            path: "hodDashboard",
            component: <HodDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "wfm",
            component: <HOD />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          //
          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },
          {
            path: "hodDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "RequisitionManagement",
            component: <RequisitionManagement />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },

          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "hod",
        component: <HodDashboardd />,
      },
    ],
  },
  {
    role: "HR",
    isProtected: true,
    defaultRoute: "hr/hrDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <HrDashboard />,
          },
          {
            path: "hrDashboard",
            component: <HrDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "hrRequestManagement",
            component: <HRRequestsManagementTab />,
          },
          {
            path: "wfm",
            component: <Workforcemangement />,
          },

          {
            path: "career",
            component: <Career />,
          },
          {
            path: "performancemanagement",
            component: <PerformanceManagement />,
          },
          {
            path: "payrollmanagement",
            component: <PayrollManagement />,
          },
          {
            path: "stationaryManagement",
            component: <StationaryManagement />,
          },
          {
            path: "setting",
            component: <Setting />,
          },


          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },

          {
            path: "hrDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "userdetailscrud/:emp_code/:email/letterhead",
            component: <Letter />,
          },

          {
            path: "AppraisalSheet",
            component: <AppraisalSheet />,
          },

          // {
          //   path: "RequisitionManagement",
          //   component: <RequisitionManagement/>
          // },

          {
            path: "salarySlip/:year/:month/:id/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlip/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },

          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },




          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },

          {
            path: "educationDetailss/:email/",
            component: <PersonaldetailsEducationdetails />,
          },

          {
            path: "workexperiencee/:email/",
            component: <Personaldetailsworkexprience />,
          },

          {
            path: "emergencycontacts/:email/",
            component: <PersonaldetailsEmergencycontactdetails />,
          },

          {
            path: "accountdetailss/:email/",
            component: <PersonaldetailsAccountdetails />,
          },

          {
            path: "documentdetailss/:email/",
            component: <PersonaldetailsDocumentdetails />,
          },


        ],
        path: "hr",
        component: <HrDashboardd />,
      },
    ],
  },

  {
    role: "Admin",
    isProtected: true,
    defaultRoute: "admin/adminDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <AdminDashboard />,
          },
          {
            path: "adminDashboard",
            component: <AdminDashboard />,
          },
          {
            path: "adminMenu",
            component: <AdminMenu />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "attendanceAndRequest",
            component: <AttendanceAndSelfRequestManagement />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "teamRequestManagement",
            component: <TeamRequestsManagementTab />,
          },
          {
            path: "hrRequestManagement",
            component: <HRRequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "wfm",
            component: <Workforcemangement />,
          },
          {
            path: "career",
            component: <Career />,
          },
          {
            path: "setting",
            component: <Setting />,
          },
          {
            path: "ticketSystem",
            component: <TicketsManagement />,
          },
          {
            path: "ticket/:id/",
            component: <ViewTicket />,
          },
          {
            path: "performancemanagement",
            component: <PerformanceManagement />,
          },
          {
            path: "payrollmanagement",
            component: <PayrollManagement />,
          },
          {
            path: "stationaryManagement",
            component: <StationaryManagement />,
          },


          {
            path: "websiteManagement",
            component: <WebsiteManagement />,
          },

          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },
          {
            path: "ProjectContractorDetails",
            component: <ProjectContractorDetails />,
          },
          {
            path: "ProjectConsultantDetails",
            component: <ProjectConsultantDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },

          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },
          {
            path: "hrDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "wfm/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetailscrud/:emp_code/:email/letterhead",
            component: <Letter />,
          },

          {
            path: "AppraisalSheet",
            component: <AppraisalSheet />,
          },
          {
            path: "salarySlip/:year/:month/:id/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlip/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "dynamicSalarySlip",
            component: <PrintDynamicSalarySlipData />,
          },
          {
            path: "upcomingSalarySlip",
            component: <PrintDynamicUpcomingSalarySlip />,
          },

          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },


          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "educationDetailss/:email/",
            component: <PersonaldetailsEducationdetails />,
          },
          {
            path: "workexperiencee/:email/",
            component: <Personaldetailsworkexprience />,
          },
          {
            path: "emergencycontacts/:email/",
            component: <PersonaldetailsEmergencycontactdetails />,
          },
          {
            path: "accountdetailss/:email/",
            component: <PersonaldetailsAccountdetails />,
          },

          {
            path: "documentdetailss/:email/",
            component: <PersonaldetailsDocumentdetails />,
          },
        ],
        path: "admin",
        component: <AdminDashboardd />,
      },
    ],
  },
];

// nestedRoutes: {
//   path: "",
//   component: "",
//   routes: [{
//     path: "",
//     component:"",
//   }],
//   nestedRoutes: [{}]
// }
