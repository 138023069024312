import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import Logo from "../../assets/images/loginimage.png"
import NewYearGif from "../../assets/gif/newyear.gif"
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast } from "../../components/CustomFunctions";

const Login = () => {

  const navigate = useNavigate();
  const { updatePermissions } = usePermission(); // Use the hook

  const [errMsg, setErrMsg] = useState("");


  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });



  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});

  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    // Check if the fields have been auto-filled by the browser
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    if (emailInput && passwordInput) {
      setFormData({
        email: emailInput.value,
        password: passwordInput.value
      });
      setInputState({
        email: emailInput.value ? 'green' : '',
        password: passwordInput.value ? 'green' : '',
      });
    }
  }, []);



  const validateForm = () => {
    const newErrors = {};


    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    const requiredFields = [
      'password',
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };



  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      setLoading(true); //loading logic
      // const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      // toast.success(`Logging In...`);
      const loadingToastId = toast.loading(`Logging In...`); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/user/login/`, formData);

        if (res.status === 200) {
          toast.success(`Logging In...`);

          const { access, refresh, payload, employeecode, payload_a, letter } = res.data;

          const sessionData = {
            access_token: access,
            refresh_token: refresh,
            role: payload.role,
            email: payload.email,
            emp_code: employeecode,
            department: payload_a.department,
            department_name: payload_a.department_name,
            rh: payload_a.reporting_head,
            is_rh: payload_a.is_reporthead,
            profilepic: payload_a.profilepic,
            // letter_type: letter,
            name: payload_a.name,
            company: payload_a.division_name,
            company_id: payload_a.sub_company_id
          };

          console.log("sessionData");
          console.log(sessionData);

          Object.entries(sessionData).forEach(([key, value]) => {
            sessionStorage.setItem(key, value);
          });

          updatePermissions(); // Update permissions after setting session storage

          // Optionally, you can log updated sessionStorage
          console.log("Updated sessionStorage:", sessionStorage);

          // Navigate to the desired location after setting session data
          navigate("/");
          window.location.reload();
        } else {
          setErrMsg("Invalid Email Or Password");
        }
      } catch (err) {
        console.log(err);
        setErrMsg(err.response.data.error);
        handleErrorToast(err, loadingToastId);
        // setErrMsg("Invalid Email Or Password");
      } finally {
        setLoading(false);  //loading logic
        toast.dismiss(loadingToastId);
      }
    } else {
      console.log('Form contains errors:', errors);

    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });


    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const forgotpass = () => {
    navigate("/forgotpassword");
  }


  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent the default form submission
      handleSubmit(event);
    }
  };
  ;






  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className='login-page'>
        <div>
          <Link to="/">
            <img src={NewYearGif} alt='logo' className=' login-page-img width-25vw ' />
          </Link>
        </div>
        <div className='login-page-right width-35vw'>
          <div className='login-page-first-heading font-weight300'>Hello!</div>
          <div className='login-page-second-heading font-weight600'>Welcome Back👋</div>
          <div className='login-page-third-heading font-weight300 font-size-heading'>The world is just a step away from here </div>
          <form onSubmit={handleSubmit} onKeyDown={handleKeyPress} className="register-form">
            <div className="flex-column">
              <label htmlFor="email" className='form-labels'>Email</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="example@gmail.com"
                autoComplete="off"
                onChange={handleInputChange}
                value={formData.email}
                onInput={(e) => {
                  e.target.value = e.target.value.toLowerCase().trim();// Convert input to lowercase
                }}
                className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
              />
              {errors.email && (
                <span className="error-message font-size-text ">{errors.email}</span>
              )}</div>
            <div className="flex-column">

              <label htmlFor="password" className='form-labels'>Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="password"
                  name="password"
                  onChange={handleInputChange}
                  value={formData.password}

                  className={`form-input ${errors.password ? 'error' : inputState.password ? 'success' : ''}`}
                />
                {errors.password && (
                  <span className="error-message font-size-text ">{errors.password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 10" fill="none">
                    <path d="M15.4569 4.7975C15.435 4.74813 14.9056 3.57375 13.7287 2.39687C12.1606 0.82875 10.18 0 7.99999 0C5.81999 0 3.83937 0.82875 2.27124 2.39687C1.09437 3.57375 0.562494 4.75 0.543119 4.7975C0.51469 4.86144 0.5 4.93064 0.5 5.00062C0.5 5.0706 0.51469 5.1398 0.543119 5.20375C0.564994 5.25312 1.09437 6.42688 2.27124 7.60375C3.83937 9.17125 5.81999 10 7.99999 10C10.18 10 12.1606 9.17125 13.7287 7.60375C14.9056 6.42688 15.435 5.25312 15.4569 5.20375C15.4853 5.1398 15.5 5.0706 15.5 5.00062C15.5 4.93064 15.4853 4.86144 15.4569 4.7975ZM7.99999 9C6.07624 9 4.39562 8.30062 3.00437 6.92188C2.43352 6.35418 1.94786 5.70685 1.56249 5C1.94776 4.29309 2.43343 3.64574 3.00437 3.07812C4.39562 1.69938 6.07624 1 7.99999 1C9.92374 1 11.6044 1.69938 12.9956 3.07812C13.5676 3.6456 14.0543 4.29295 14.4406 5C13.99 5.84125 12.0269 9 7.99999 9ZM7.99999 2C7.40665 2 6.82663 2.17595 6.33328 2.50559C5.83994 2.83524 5.45542 3.30377 5.22836 3.85195C5.00129 4.40013 4.94188 5.00333 5.05764 5.58527C5.17339 6.16721 5.45912 6.70176 5.87867 7.12132C6.29823 7.54088 6.83278 7.8266 7.41472 7.94236C7.99667 8.05811 8.59987 7.9987 9.14804 7.77164C9.69622 7.54458 10.1648 7.16006 10.4944 6.66671C10.824 6.17336 11 5.59334 11 5C10.9992 4.2046 10.6828 3.44202 10.1204 2.87959C9.55797 2.31716 8.79539 2.00083 7.99999 2ZM7.99999 7C7.60443 7 7.21775 6.8827 6.88885 6.66294C6.55996 6.44318 6.30361 6.13082 6.15224 5.76537C6.00086 5.39991 5.96125 4.99778 6.03842 4.60982C6.11559 4.22186 6.30608 3.86549 6.58578 3.58579C6.86549 3.30608 7.22185 3.1156 7.60981 3.03843C7.99778 2.96126 8.39991 3.00087 8.76536 3.15224C9.13081 3.30362 9.44317 3.55996 9.66293 3.88886C9.8827 4.21776 9.99999 4.60444 9.99999 5C9.99999 5.53043 9.78928 6.03914 9.41421 6.41421C9.03913 6.78929 8.53043 7 7.99999 7Z" fill="#707070" />
                  </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                    <path d="M4.28016 11.82C2.7935 10.8467 1.8335 9.37999 1.8335 8.09332C1.8335 5.90665 4.5935 3.22665 8.00016 3.22665C9.3935 3.22665 10.6868 3.67332 11.7268 4.36665" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2332 5.74033C13.8272 6.49366 14.1732 7.32699 14.1732 8.09366C14.1732 10.2803 11.4066 12.9603 7.99992 12.9603C7.39325 12.9603 6.80058 12.8737 6.24658 12.7203" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M6.51072 9.57821C6.11406 9.18554 5.89206 8.65021 5.89406 8.09221C5.89139 6.92888 6.83272 5.98354 7.99672 5.98154C8.55672 5.98021 9.09406 6.20221 9.49006 6.59821" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M10.0732 8.46631C9.91717 9.32764 9.24317 10.003 8.38184 10.161" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2611 2.8335L2.74512 13.3495" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                  </svg>)}
                </button>
              </div>
            </div>
            <div>
              <button type="submit" className="login-button font-weight600  font-size-heading" disabled={loading}>
                Sign In
              </button>
            </div>
            <button className="forgotbutton  font-weight600  font-size-text " onClick={forgotpass}>Forgot Password ?</button>
            <br></br>
            <p
              style={{ color: "red" }}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

          </form>

        </div>
      </div>
    </>
  )
}

export default Login