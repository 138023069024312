import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, handleAllError, handleErrorToast } from "../../CustomFunctions";
import { DropdownArrowOption, UpdatePencil } from "../../AllSvg";
import usePermission from "../../../config/permissions";

const AddNewEmployeeData = ({ getAllEmployee, SubCompany }) => {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getDepartment();
        getDesignation();
        getAttendpolicy();
        getWeeklypolicy();
        getReportingHeads();
        getWorksiteList();
        setShow(true)
    };

    const [formData, setFormData] = useState({
        emp_code: "",
        machine_code: "",
        user_detail: "",
        department: "",
        designation: "",
        is_reporthead: "",
        leavebalance: "0",
        casual_leave: "0",
        sick_leave: "0",
        earn_leave: "0",
        employee_type: "",
        joining_date: "",
        location: "",
        division: sessionStorage.getItem('company_id') || '',
        attendance_policy: "",
        weeklyoff_policy: "",
        employee_status: "active",
        role: "",
        cost_center_assets: "employee",
        show_herarchy: true,
        url: FRONTEND_URL
    });

    // ?  ************** Data Fetch start **************  ? //
    const [buffer1, setBuffering1] = useState(true);
    const [buffer2, setBuffering2] = useState(true);
    const [buffer3, setBuffering3] = useState(true);
    const [buffer4, setBuffering4] = useState(true);
    const [buffer5, setBuffering5] = useState(true);
    const [buffer6, setBuffering6] = useState(true);

    const [departmentname, setDepartmentname] = useState([]);
    const [designationname, setDesignationname] = useState([])
    const [attendpolicy, setAttendpolicy] = useState([]);
    const [weeklypolicy, setWeeklypolicy] = useState([]);
    const [rhname, setRhname] = useState([]);
    const [worksite, setWorksite] = useState([]);

    const getDepartment = async () => {
        try {
            setBuffering1(true)
            // const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setDepartmentname(dep.data)
        } catch (err) {
        } finally {
            setBuffering1(false)
        }
    };

    const getDesignation = async () => {
        try {
            setBuffering2(true)
            // const des = await axios.get(`${BASE_URL}/wfm/designationfilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const des = await axios.get(`${BASE_URL}/wfm/designationfilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setDesignationname(des.data)
        } catch (err) {
        } finally {
            setBuffering2(false)
        }
    };

    const getAttendpolicy = async () => {
        try {
            setBuffering3(true)
            // const att = await axios.get(`${BASE_URL}/wfm/attendancepolicyfilterall/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const att = await axios.get(`${BASE_URL}/wfm/attendancepolicyfilterall/${formData.division || 'null'}/active/`);
            setAttendpolicy(att.data);
        } catch (err) {
        } finally {
            setBuffering3(false)
        }
    };

    const getWeeklypolicy = async () => {
        try {
            setBuffering4(true)
            // const week = await axios.get(`${BASE_URL}/wfm/weeklyoffpolicyfilterall/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const week = await axios.get(`${BASE_URL}/wfm/weeklyofffilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setWeeklypolicy(week.data);
        } catch (err) {
        } finally {
            setBuffering4(false)
        }
    };

    const getWorksiteList = async () => {
        try {
            setBuffering5(true)
            // const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setWorksite(res.data);
        } catch (err) {
        } finally {
            setBuffering5(false)
        }
    };

    const getReportingHeads = async () => {
        try {
            setBuffering6(true)
            // const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            // const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/${formData.division || 'null'}/active/`);
            const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/null/active/`);
            setRhname(res.data);
        } catch (err) {
        } finally {
            setBuffering6(false)
        }
    };

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            department: "",
            designation: "",
            attendance_policy: "",
            weeklyoff_policy: "",
            location: "",
            reporting_head: ""
        }));
        getDepartment();
        getDesignation();
        getAttendpolicy();
        getWeeklypolicy();
        // getReportingHeads();
        getWorksiteList();
    }, [formData?.division]);

    // !  ************** Data Fetch end **************  ! //

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "emp_code",
            "department",
            'machine_code',
            "designation",
            "is_reporthead",
            "employee_type",
            "joining_date",
            "location",
            "division",
            "attendance_policy",
            "weeklyoff_policy",
            "role"
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let newPassword = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                newPassword += allChars[randomIndex];
            }

            const updatedFormData = {
                ...formData,
                user_detail: formData.email,
                employee: formData.email,
                email: formData.email,
                password: newPassword,
                // role: "Employee",
            };

            try {
                let res = await axios.post(`${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`, updatedFormData)
                if (res.status === 200) {
                    handleClose()
                    await getAllEmployee()
                }
            } catch (err) {
                //toast Logic
                handleAllError(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button title="Add New Employee" className="models-button model-add" onClick={handleShow} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    id="hiring">
                    <g id="Layer_2">
                        <path fill="#2576BC" d="M18,16c-0.5522,0-1,0.4473-1,1v1h-1c-0.5522,0-1,0.4473-1,1s0.4478,1,1,1h1v1c0,0.5527,0.4478,1,1,1s1-0.4473,1-1v-1h1 c0.5522,0,1-0.4473,1-1s-0.4478-1-1-1h-1v-1C19,16.4473,18.5522,16,18,16z"></path>
                        <path fill="#2576BC" d="M5.2554 15.4678l-.54 1.8887c-.249.8701-.0791 1.7832.4658 2.5059S6.5581 21 7.4634 21H13c.5522 0 1-.4473 1-1s-.4478-1-1-1H7.4634c-.272 0-.5215-.124-.6851-.3418-.1636-.2168-.2148-.4902-.1401-.752l.54-1.8896C7.5176 14.8291 8.6167 14 9.8516 14h4.2969c.7061 0 1.3799.2656 1.897.7471.4028.376 1.0366.3555 1.4131-.0498.3765-.4033.3545-1.0361-.0498-1.4131C16.5205 12.4561 15.3628 12 14.1484 12H9.8516C7.728 12 5.8379 13.4258 5.2554 15.4678zM16 7c0-2.2061-1.7944-4-4-4S8 4.7939 8 7s1.7944 4 4 4S16 9.2061 16 7zM10 7c0-1.1025.897-2 2-2s2 .8975 2 2-.897 2-2 2S10 8.1025 10 7z"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>New Employee Onboarding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="register-form">
                            <div className="form-flex-wrap">

                                <div title="Name" className="flex-column">
                                    <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                                        Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Candidate name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.name
                                            ? "error"
                                            : inputState.name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.name && (
                                        <span className="error-message font-size-text ">{errors.name}</span>
                                    )}
                                </div>

                                <div title="Email" className="flex-column">
                                    <label htmlFor="email" className="form-labels font-weight500 font-size-subheading">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Demo@gmail.com"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.email
                                            ? "error"
                                            : inputState.email
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.email && (
                                        <span className="error-message font-size-text ">{errors.email}</span>
                                    )}
                                </div>

                                <div title="Emp Code" className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels font-weight500 font-size-subheading">
                                        Emp Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message font-size-text ">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div title="Machine Code" className="flex-column">
                                    <label htmlFor="machine_code" className="form-labels font-weight500 font-size-subheading">
                                        Machine Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="machine_code"
                                        name="machine_code"
                                        placeholder="Machine Code"
                                        onChange={handleInputChange}
                                        value={formData.machine_code}
                                        className={`form-input ${errors.machine_code
                                            ? "error"
                                            : inputState.machine_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.machine_code && (
                                        <span className="error-message font-size-text ">{errors.machine_code}</span>
                                    )}
                                </div>

                                <div title="Joining Date" className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels font-weight500 font-size-subheading">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                                </div>

                                <div title="Sub Company List" className="flex-column form-group-selectt">
                                    <label htmlFor="division" className="form-labels font-weight500 font-size-subheading">
                                        Division<span className="required">*</span>
                                    </label>
                                    <br />

                                    <select
                                        id="division"
                                        name="division"
                                        value={formData.division}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.division ? "error" : inputState.division ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Division</option>
                                        {SubCompany?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))}
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.division && (
                                            <span className="error-message font-size-text ">{errors.division}</span>
                                        )}
                                    </div>

                                </div>

                                <div title="Departments List" className="flex-column form-group-selectt">
                                    <label htmlFor="department" className="form-labels font-weight500 font-size-subheading">
                                        Department<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer1 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="department"
                                                name="department"
                                                value={formData.department}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Department</option>
                                                {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.department && (
                                                    <span className="error-message font-size-text ">{errors.department}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Designations List" className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels font-weight500 font-size-subheading">
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer2 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="designation"
                                                name="designation"
                                                value={formData.designation}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Designation</option>
                                                {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.designation && (
                                                    <span className="error-message font-size-text ">{errors.designation}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Attendance Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="attendance_policy" className="form-labels font-weight500 font-size-subheading">
                                        Attendance Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer3 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="attendance_policy"
                                                name="attendance_policy"
                                                value={formData.attendance_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.attendance_policy ? "error" : inputState.attendance_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Attendance Policy</option>
                                                {attendpolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}-{i.sub_company_title}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.attendance_policy && (
                                                    <span className="error-message font-size-text ">{errors.attendance_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="WeeklyOff Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="weeklyoff_policy" className="form-labels font-weight500 font-size-subheading">
                                        WeeklyOff Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer4 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="weeklyoff_policy"
                                                name="weeklyoff_policy"
                                                value={formData.weeklyoff_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.weeklyoff_policy ? "error" : inputState.weeklyoff_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Weeklyoff Policy</option>
                                                {weeklypolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.weeklyoff_policy && (
                                                    <span className="error-message font-size-text ">{errors.weeklyoff_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Work Location List" className="flex-column form-group-selectt">
                                    <label htmlFor="location" className="form-labels font-weight500 font-size-subheading">
                                        Work Site<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer5 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.location ? "error" : inputState.location ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a location</option>
                                                {worksite?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.location && (
                                                    <span className="error-message font-size-text ">{errors.location}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Reporting Heads List" className="flex-column form-group-selectt">
                                    <label htmlFor="reporting_head" className="form-labels font-weight500 font-size-subheading">
                                        Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer6 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="reporting_head"
                                                name="reporting_head"
                                                value={formData.reporting_head}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.reporting_head ? "error" : inputState.reporting_head ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Reporting Head</option>
                                                {rhname?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.user_detail}>{i.name}-{i.division_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.reporting_head && (
                                                    <span className="error-message font-size-text ">{errors.reporting_head}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Is Reporting Head" className="flex-column form-group-selectt">
                                    <label htmlFor="is_reporthead" className="form-labels font-weight500 font-size-subheading">
                                        Is Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="is_reporthead"
                                        name="is_reporthead"
                                        value={formData.is_reporthead}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.is_reporthead ? "error" : inputState.is_reporthead ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="True">Yes</option>
                                        <option value="False">No</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.is_reporthead && (
                                            <span className="error-message font-size-text ">{errors.is_reporthead}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Dashboard Employee Roles" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Role<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.role ? "error" : inputState.role ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Role</option>
                                        <option value="Employee">Employee</option>
                                        <option value="ReportingHead">Team Lead</option>
                                        <option value="HOD">HOD</option>
                                        <option value="HR">HR</option>
                                        <option value="Admin">Admin</option>
                                        <option value="IT">IT</option>
                                        <option value="Account">Accounts</option>
                                        <option value="SiteManagement">Site Management</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.role && (
                                            <span className="error-message font-size-text ">{errors.role}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Employee Type" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee_type"
                                        name="employee_type"
                                        value={formData.employee_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Employee</option>
                                        <option value="permanent">Permanent</option>
                                        <option value="temporary">Temporary</option>
                                        <option value="probation">Probation</option>
                                        <option value="termination">Termination</option>
                                        <option value="resigned">Resigned</option>
                                        <option value="absconding">Absconding</option>
                                        <option value="healthissue">Healthissue</option>
                                        <option value="intern">Intern</option>
                                        <option value="trainee">Trainee</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.employee_type && (
                                            <span className="error-message font-size-text ">{errors.employee_type}</span>
                                        )}
                                    </div>
                                </div>

                                {/* <div title="Employeee Cost-Center Assets" className="flex-column form-group-selectt">
                                    <label htmlFor="cost_center_assets" className="form-labels font-weight500 font-size-subheading">
                                        Cost Center Assets<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="cost_center_assets"
                                        name="cost_center_assets"
                                        value={formData.cost_center_assets}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.cost_center_assets ? "error" : inputState.cost_center_assets ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Cost Center Assets</option>
                                        <option value="employee">Employee</option>
                                        <option value="currentasset">Current Asset</option>
                                        <option value="fixedasset">Fixed Asset</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.cost_center_assets && (
                                            <span className="error-message font-size-text ">{errors.cost_center_assets}</span>
                                        )}
                                    </div>
                                </div> */}

                            </div>
                            <div className="flex-column ">
                                <div className="flex-row ">
                                    <div className="checkbox-toggle">
                                        <input
                                            type="checkbox"
                                            id="toggle_show_herarchy"
                                            name="show_herarchy"
                                            checked={formData.show_herarchy}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="toggle_show_herarchy"></label>
                                    </div>
                                    <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                    <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                                        {formData.show_herarchy ? "View" : "Hide"} in Hierarchy {" "}
                                    </label>
                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleFormSubmit} className="model-button font-weight500 model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>
                            </div>
                        </form ></>

                </Modal.Body >
            </Modal >
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const UpdateEmployeeData = ({ i, email, getAllEmployee, SubCompany }) => {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getEmployeeOrgData(i);
        getDepartment();
        getDesignation();
        getAttendpolicy();
        getWeeklypolicy();
        getReportingHeads();
        getWorksiteList();
        setShow(true)
    };

    const [formData, setFormData] = useState({
        emp_code: "",
        machine_code: "",
        user_detail: email || "",
        department: "",
        designation: "",
        // is_reporthead: "false",
        leavebalance: "0",
        casual_leave: "0",
        sick_leave: "0",
        earn_leave: "0",
        employee_type: "",
        joining_date: "",
        location: "",
        division: sessionStorage.getItem('company_id') || '',
        attendance_policy: "",
        weeklyoff_policy: "",
        cost_center_assets: "",
        employee_status: "active",
        role: "",
        url: FRONTEND_URL
    });

    // ?  ************** Data Fetch start **************  ? //
    const [buffer1, setBuffering1] = useState(true);
    const [buffer2, setBuffering2] = useState(true);
    const [buffer3, setBuffering3] = useState(true);
    const [buffer4, setBuffering4] = useState(true);
    const [buffer5, setBuffering5] = useState(true);
    const [buffer6, setBuffering6] = useState(true);
    const [divisionChangeNoticeError, setDivisionChangeNotice] = useState("");
    const [DivisionChangeConfirmation, setDivisionChangeConfirmation] = useState(false);

    const [employeeOrgDetails, setEmployeeOrgDetails] = useState([]);
    const [departmentname, setDepartmentname] = useState([]);
    const [designationname, setDesignationname] = useState([])
    const [attendpolicy, setAttendpolicy] = useState([]);
    const [weeklypolicy, setWeeklypolicy] = useState([]);
    const [rhname, setRhname] = useState([]);
    const [worksite, setWorksite] = useState([]);


    const getEmployeeOrgData = async (i) => {
        try {
            const emp = await axios.get(
                `${BASE_URL}/wfm/ourcompanydetailsbyemp/${i.emp_code}/`
            );
            setEmployeeOrgDetails(emp.data);
            setFormData(emp.data);
        } catch (err) {
            //toast Logic
            handleAllError(err);
        }
    };

    const getDepartment = async (div) => {
        try {
            setBuffering1(true)
            // const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${div ? div : formData.division || 'null'}/active/`);
            setDepartmentname(dep.data)
        } catch (err) {
        } finally {
            setBuffering1(false)
        }
    };

    const getDesignation = async (div) => {
        try {
            setBuffering2(true)
            // const des = await axios.get(`${BASE_URL}/wfm/designationfilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const des = await axios.get(`${BASE_URL}/wfm/designationfilterbysubcompanycount/${div ? div : formData.division || 'null'}/active/`);
            setDesignationname(des.data)
        } catch (err) {
        } finally {
            setBuffering2(false)
        }
    };

    const getAttendpolicy = async (div) => {
        try {
            setBuffering3(true)
            // const att = await axios.get(`${BASE_URL}/wfm/attendancepolicyfilterall/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const att = await axios.get(`${BASE_URL}/wfm/attendancepolicyfilterall/${div ? div : formData.division || 'null'}/active/`);
            setAttendpolicy(att.data);
        } catch (err) {
        } finally {
            setBuffering3(false)
        }
    };

    const getWeeklypolicy = async (div) => {
        try {
            setBuffering4(true)
            // const week = await axios.get(`${BASE_URL}/wfm/weeklyoffpolicyfilterall/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const week = await axios.get(`${BASE_URL}/wfm/weeklyoffpolicyfilterall/${div ? div : formData.division || 'null'}/active/`);
            setWeeklypolicy(week.data);
        } catch (err) {
        } finally {
            setBuffering4(false)
        }
    };

    const getWorksiteList = async (div) => {
        try {
            setBuffering5(true)
            // const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${div ? div : formData.division || 'null'}/active/`);
            setWorksite(res.data);
        } catch (err) {
        } finally {
            setBuffering5(false)
        }
    };

    const getReportingHeads = async (div) => {
        try {
            setBuffering6(true)
            // const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/${formData.division || sessionStorage.getItem('company_id')}/active/`);
            // const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/${formData.division || 'null'}/active/`);
            const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/null/active/`);
            setRhname(res.data);
        } catch (err) {
        } finally {
            setBuffering6(false)
        }
    };

    // !  ************** Data Fetch end **************  ! //

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "emp_code",
            "department",
            "designation",
            // "is_reporthead",
            // "leavebalance",
            // "casual_leave",
            // "sick_leave",
            // "earn_leave",
            "employee_type",
            "joining_date",
            "reporting_head",
            "location",
            "division",
            "attendance_policy",
            "weeklyoff_policy",
            "role",
            formData.employee_status === "inactive" ? "last_working_date" : null,
        ].filter(Boolean);
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        if (name === "division") {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                department: "",
                designation: "",
                attendance_policy: "",
                weeklyoff_policy: "",
                location: "",
                reporting_head: ""
            }));
            getDepartment(value);
            getDesignation(value);
            getAttendpolicy(value);
            getWeeklypolicy(value);
            getReportingHeads(value);
            getWorksiteList(value);
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`,
                    formData
                );
                if (res.status === 200) {
                    await getAllEmployee();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleAllError(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };





    return (
        <>
            <button
                className="employee-basic-details-edit"
                onClick={handleShow}
                title="Edit Company Details"
            >
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Employee Details of "{formData.emp_code} : {formData.name}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="register-form">
                            <div className="form-flex-wrap">

                                <div title="Email" style={{ cursor: 'not-allowed' }} className="flex-column">
                                    <label htmlFor="user_detail" className="form-labels font-weight500 font-size-subheading">
                                        Email <span className="required">(View-Only)</span>
                                    </label>
                                    <input
                                        type="user_detail"
                                        id="user_detail"
                                        name="user_detail"
                                        placeholder="Demo@gmail.com"
                                        readOnly
                                        style={{ cursor: 'not-allowed' }}
                                        onChange={handleInputChange}
                                        value={formData.user_detail}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.user_detail
                                            ? "error"
                                            : inputState.user_detail
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.user_detail && (
                                        <span className="error-message font-size-text ">{errors.user_detail}</span>
                                    )}
                                </div>

                                <div title="Emp Code" style={{ cursor: 'not-allowed' }} className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels font-weight500 font-size-subheading">
                                        Emp Code <span className="required">(View-Only)</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        readOnly
                                        style={{ cursor: 'not-allowed' }}
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message font-size-text ">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div title="Machine Code" className="flex-column">
                                    <label htmlFor="machine_code" className="form-labels font-weight500 font-size-subheading">
                                        Machine Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="machine_code"
                                        name="machine_code"
                                        placeholder="Machine Code"
                                        onChange={handleInputChange}
                                        value={formData.machine_code}
                                        className={`form-input ${errors.machine_code
                                            ? "error"
                                            : inputState.machine_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.machine_code && (
                                        <span className="error-message font-size-text ">{errors.machine_code}</span>
                                    )}
                                </div>

                                <div title="Sub Company List" className="flex-column form-group-selectt">
                                    <label htmlFor="division" className="form-labels font-weight500 font-size-subheading">
                                        Division<span className="required">*</span>
                                    </label>
                                    <br />

                                    <select
                                        id="division"
                                        name="division"
                                        value={formData.division}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.division ? "error" : inputState.division ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Division</option>
                                        {SubCompany?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))}
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.division && (
                                            <span className="error-message font-size-text ">{errors.division}</span>
                                        )}
                                    </div>

                                </div>

                                <div title="Departments List" className="flex-column form-group-selectt">
                                    <label htmlFor="department" className="form-labels font-weight500 font-size-subheading">
                                        Department<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer1 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="department"
                                                name="department"
                                                value={formData.department}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Department</option>
                                                {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.department && (
                                                    <span className="error-message font-size-text ">{errors.department}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Designations List" className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels font-weight500 font-size-subheading">
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer2 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="designation"
                                                name="designation"
                                                value={formData.designation}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Designation</option>
                                                {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.designation && (
                                                    <span className="error-message font-size-text ">{errors.designation}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Attendance Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="attendance_policy" className="form-labels font-weight500 font-size-subheading">
                                        Attendance Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer3 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="attendance_policy"
                                                name="attendance_policy"
                                                value={formData.attendance_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.attendance_policy ? "error" : inputState.attendance_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Attendance Policy</option>
                                                {attendpolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.attendance_policy && (
                                                    <span className="error-message font-size-text ">{errors.attendance_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="WeeklyOff Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="weeklyoff_policy" className="form-labels font-weight500 font-size-subheading">
                                        WeeklyOff Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer4 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="weeklyoff_policy"
                                                name="weeklyoff_policy"
                                                value={formData.weeklyoff_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.weeklyoff_policy ? "error" : inputState.weeklyoff_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Weeklyoff Policy</option>
                                                {weeklypolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.weeklyoff_policy && (
                                                    <span className="error-message font-size-text ">{errors.weeklyoff_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Work Location List" className="flex-column form-group-selectt">
                                    <label htmlFor="location" className="form-labels font-weight500 font-size-subheading">
                                        Work Site<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer5 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.location ? "error" : inputState.location ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a location</option>
                                                {worksite?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.location && (
                                                    <span className="error-message font-size-text ">{errors.location}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Reporting Heads List" className="flex-column form-group-selectt">
                                    <label htmlFor="reporting_head" className="form-labels font-weight500 font-size-subheading">
                                        Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer6 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="reporting_head"
                                                name="reporting_head"
                                                value={formData.reporting_head}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.reporting_head ? "error" : inputState.reporting_head ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Reporting Head</option>
                                                {rhname?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.user_detail}>{i.name}-{i.division_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.reporting_head && (
                                                    <span className="error-message font-size-text ">{errors.reporting_head}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Is Reporting Head" className="flex-column form-group-selectt">
                                    <label htmlFor="is_reporthead" className="form-labels  font-weight500    font-size-subheading">
                                        Is Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="is_reporthead"
                                        name="is_reporthead"
                                        value={formData.is_reporthead}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.is_reporthead
                                            ? "error"
                                            : inputState.is_reporthead
                                                ? "success"
                                                : ""
                                            }`}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.is_reporthead && (
                                            <span className="error-message font-size-text ">{errors.is_reporthead}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Dashboard Employee Roles" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Role<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.role ? "error" : inputState.role ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Role</option>
                                        <option value="Employee">Employee</option>
                                        <option value="ReportingHead">Team Lead</option>
                                        <option value="HOD">HOD</option>
                                        <option value="HR">HR</option>
                                        <option value="Admin">Admin</option>
                                        <option value="IT">IT</option>
                                        <option value="Account">Accounts</option>
                                        <option value="SiteManagement">Site Management</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.role && (
                                            <span className="error-message font-size-text ">{errors.role}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Employeee Cost-Center Assets" className="flex-column form-group-selectt">
                                    <label htmlFor="cost_center_assets" className="form-labels font-weight500 font-size-subheading">
                                        Cost Center Assets<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="cost_center_assets"
                                        name="cost_center_assets"
                                        value={formData.cost_center_assets}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.cost_center_assets ? "error" : inputState.cost_center_assets ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Cost Center Assets</option>
                                        <option value="employee">Employee</option>
                                        <option value="currentasset">Current Asset</option>
                                        <option value="fixedasset">Fixed Asset</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.cost_center_assets && (
                                            <span className="error-message font-size-text ">{errors.cost_center_assets}</span>
                                        )}
                                    </div>
                                </div>

                            </div>

                            <div className="jd-heading-outer heading-bg-color-white">
                                <div className="jd-heading-outer-flex">
                                    <h2 className="jd-heading-main font-weight600  font-size-heading">
                                        Employee Status{" "}
                                    </h2>
                                </div>
                                <div>
                                    <div className="jd-heading-bottom-bold"></div>
                                    <div className="jd-heading-bottom-light"></div>
                                </div>

                            </div>

                            <div className="form-flex-wrap">

                                <div title="Joining Date" className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels font-weight500 font-size-subheading">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                                </div>

                                <div title="Employee Status" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_status" className="form-labels font-weight500 font-size-subheading">
                                        Employee Status<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee_status"
                                        name="employee_status"
                                        value={formData.employee_status}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee_status
                                            ? "error"
                                            : inputState.employee_type
                                                ? "success"
                                                : ""
                                            }`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="active">Active</option>
                                        {/* <option value="action Required"</option> */}
                                        <option value="inactive">Inactive</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.employee_status && (
                                            <span className="error-message font-size-text ">
                                                {errors.employee_status}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div title="Employee Type" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee_type"
                                        name="employee_type"
                                        value={formData.employee_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Employee</option>
                                        <option value="permanent">Permanent</option>
                                        <option value="temporary">Temporary</option>
                                        <option value="probation">Probation</option>
                                        <option value="termination">Termination</option>
                                        <option value="resigned">Resigned</option>
                                        <option value="absconding">Absconding</option>
                                        <option value="healthissue">Healthissue</option>
                                        <option value="intern">Intern</option>
                                        <option value="trainee">Trainee</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.employee_type && (
                                            <span className="error-message font-size-text ">{errors.employee_type}</span>
                                        )}
                                    </div>
                                </div>



                                <div title="Last Working Date" className="flex-column">
                                    <label htmlFor="last_working_date" className="form-labels  font-weight500    font-size-subheading">
                                        Last Working Date
                                        {formData.employee_status === "inactive" ? (
                                            <span className="required">*</span>
                                        ) : (
                                            ""
                                        )}
                                    </label>
                                    <input
                                        type="date"
                                        id="last_workig_date"
                                        name="last_working_date"
                                        onChange={handleInputChange}
                                        value={formData.last_working_date}
                                        className={`form-input ${errors.last_working_date
                                            ? "error"
                                            : inputState.last_working_date
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.last_working_date && (
                                        <span className="error-message font-size-text ">
                                            {errors.last_working_date}
                                        </span>
                                    )}
                                </div>



                            </div>

                            <div className="jd-heading-outer heading-bg-color-white">
                                <div className="jd-heading-outer-flex">
                                    <h2 className="jd-heading-main font-weight600  font-size-heading">
                                        Leave Balance{" "}
                                    </h2>
                                </div>
                                <div>
                                    <div className="jd-heading-bottom-bold"></div>
                                    <div className="jd-heading-bottom-light"></div>
                                </div>
                            </div>
                            <div className="form-flex-wrap">

                                <div title="Previous Leave Balance" className="flex-column">
                                    <label htmlFor="leavebalance" className="form-labels  font-weight500    font-size-subheading">
                                        Previous Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="leavebalance"
                                        name="leavebalance"
                                        placeholder="Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.leavebalance}
                                        className={`form-input ${errors.leavebalance
                                            ? "error"
                                            : inputState.leavebalance
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.leavebalance && (
                                        <span className="error-message font-size-text ">{errors.leavebalance}</span>
                                    )}
                                </div>

                                <div title="Casual Leave Balance" className="flex-column">
                                    <label htmlFor="casual_leave" className="form-labels  font-weight500    font-size-subheading">
                                        Casual Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="casual_leave"
                                        name="casual_leave"
                                        placeholder="Casual Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.casual_leave}
                                        className={`form-input ${errors.casual_leave
                                            ? "error"
                                            : inputState.casual_leave
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.casual_leave && (
                                        <span className="error-message font-size-text ">{errors.casual_leave}</span>
                                    )}
                                </div>

                                <div title="Sick Leave Balance" className="flex-column">
                                    <label htmlFor="sick_leave" className="form-labels  font-weight500    font-size-subheading">
                                        Sick Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="sick_leave"
                                        name="sick_leave"
                                        placeholder="Sick Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.sick_leave}
                                        className={`form-input ${errors.sick_leave
                                            ? "error"
                                            : inputState.sick_leave
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.sick_leave && (
                                        <span className="error-message font-size-text ">{errors.sick_leave}</span>
                                    )}
                                </div>

                                <div title="Earn Leave Balance" className="flex-column">
                                    <label htmlFor="Earn Leave" className="form-labels  font-weight500    font-size-subheading">
                                        Earn Leave Balance <span className="required">(View-Only)</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="earn_leave"
                                        name="earn_leave"
                                        readOnly
                                        placeholder="Earn Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.earn_leave}
                                        className={`form-input ${errors.earn_leave
                                            ? "error"
                                            : inputState.earn_leave
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.earn_leave && (
                                        <span className="error-message font-size-text ">{errors.earn_leave}</span>
                                    )}
                                </div>
                            </div>
                            <div className="jd-heading-outer heading-bg-color-white">
                                <div className="jd-heading-outer-flex">
                                    <h2 className="jd-heading-main font-weight600  font-size-heading">
                                        Visibility{" "}
                                    </h2>
                                </div>
                                <div>
                                    <div className="jd-heading-bottom-bold"></div>
                                    <div className="jd-heading-bottom-light"></div>
                                </div>
                            </div>

                            <div className="flex-row justify-evenly ">
                                <div className="flex-row ">
                                    <div className="checkbox-toggle">
                                        <input
                                            type="checkbox"
                                            id="toggle_show_herarchy"
                                            name="show_herarchy"
                                            checked={formData.show_herarchy}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="toggle_show_herarchy"></label>
                                    </div>
                                    <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                    <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                                        {formData.show_herarchy ? "View" : "Hide"} in Hierarchy {" "}
                                    </label>
                                </div>

                                <div className="flex-row ">
                                    <div className="checkbox-toggle">
                                        <input
                                            type="checkbox"
                                            id="toggle_advance_form"
                                            name="advance_form"
                                            checked={formData.advance_form}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="toggle_advance_form"></label>
                                    </div>
                                    <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                    <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                                        {formData.advance_form ? "View" : "Hide"} Advance Form {" "}
                                    </label>
                                </div>
                            </div>

                            {divisionChangeNoticeError && (
                                <div className="flex-column" style={{ marginTop: "5px" }}>
                                    <div style={{ color: "red" }}>{divisionChangeNoticeError}</div>
                                    <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                                        <input
                                            type="checkbox"
                                            checked={DivisionChangeConfirmation}
                                            onChange={(e) => setDivisionChangeConfirmation(e.target.checked)}
                                        />
                                        <> </>
                                        <span>
                                            Confirm to proceed with Division/Sub-Company change.
                                        </span>
                                        <span>
                                            (Note: Close the modal without submitting to discard changes.)
                                        </span>
                                    </label>
                                </div>
                            )}

                            <div className="button-models">
                                <button onClick={handleFormSubmit} className="model-button font-weight500 model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>
                            </div>
                        </form ></>

                </Modal.Body >
            </Modal >
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

// export default AddNewEmployeeData;
export { AddNewEmployeeData, UpdateEmployeeData };
