// const BASE_URL = "http://127.0.0.1:8000";

//@CIPL
let BASE_URL = "https://cipl.aimantra.info";

//@G-ENG
// const BASE_URL = "https://g-eng.aimantra.info";

//@SC
// const BASE_URL = "https://sc.aimantra.info";

//@Tester Lenovo
// let BASE_URL = "https://1dmjlcrf-8000.inc1.devtunnels.ms";

const currentUrl = window.location.href;
const urlParts = currentUrl.split("/");
const FRONTEND_URL = urlParts[0] + `//` + urlParts[2];

const domainParts = urlParts[2].split('.');
console.log(domainParts)
let client = domainParts[1];
if (domainParts[1] === "aimantrahrms") {
  client = domainParts[0];
  console.log("client0")

}
console.log("client1")
console.log(client)

let IMAGE_URL = "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";

if (client) {
  // BASE_URL = `https://${client}.aimantra.info`
  BASE_URL = `https://cipl.aimantra.info`
  console.log("BASE_URL A")
  console.log(BASE_URL)
}
else {
  BASE_URL = "https://cipl.aimantra.info";
  // BASE_URL = "http://localhost:8000";

  // BASE_URL = "https://lr2gx6jv-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://1dmjlcrf-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://7zfx0v9t-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://mn04ml40-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://s88t8wch-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://n00nmkv7-8000.inc1.devtunnels.ms";
  // BASE_URL = "https://vq0hl6sr-8000.inc1.devtunnels.ms";
  console.log("BASE_URL B")
  console.log(BASE_URL)
}
switch (client) {
  case "cipl":
    IMAGE_URL = "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";
    break;
  case "g-eng":
    IMAGE_URL = "https://geng-aimantra.s3.ap-south-1.amazonaws.com/";
    break;
  case "sc":
    IMAGE_URL = "https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/";
    break;
  default:
    IMAGE_URL = "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";
    // IMAGE_URL = null;
    break;
}

const AUTH = {
  headers: {
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

const AUTHH = {
  headers: {
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "multipart/form-data",
  },
};




// const PERMISSION_ROLE = sessionStorage.getItem("role") === ("HR") || sessionStorage.getItem("role") === ("Admin")
// const PERMISSION_AUTHORITY = sessionStorage.getItem("role") === ("Admin" || "SuperAdmin")

export { client, BASE_URL, AUTH, AUTHH, FRONTEND_URL, IMAGE_URL };
