import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { datearray } from "../Date";


const Calaneder = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    let previousYear = currentYear;
    let previousMonth = today.getMonth();
    if (previousMonth === 0) {
        previousYear--;
        previousMonth = 12;
    }
    const [selectedOption, setSelectedOption] = useState('this month');
    const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM")); // Default to the current month name
    const [selectedYear, setSelectedYear] = useState(moment().year()); // Default to the current year


    useEffect(() => {
        const updatedDateContext = selectedOption === 'this month' ? moment() : moment().subtract(1, 'month');
        setSelectedMonth(updatedDateContext.format("MMMM"));
        setSelectedYear(updatedDateContext.year());

        // Update datearray for the selected option
        const day = selectedOption === 'this month' ? today.getDate() : moment().subtract(1, 'month').date();
        datearray[2] = day.toString().padStart(2, '0'); // Ensure day is two digits
        getJobapppdata(selectedOption);
    }, [selectedOption]);

    const [allJobappdatadatap, setAlljobappdata] = useState([]);

    const getJobapppdata = async (selectedOption) => {
        try {
            const res = await axios.get(
                selectedOption === "this month"
                    ?
                    `${BASE_URL}/wfm/attendancefilterbyEmpByMonth/${sessionStorage.getItem('emp_code')}/${currentMonth}/${currentYear}/`
                    :
                    `${BASE_URL}/wfm/attendancefilterbyEmpByMonth/${sessionStorage.getItem('emp_code')}/${previousMonth}/${previousYear}/`
            );
            const modifiedData = res.data.map(item => {
                const datee = item.date.split("-");
                const day = datee[2];
                return {
                    ...item,
                    date: day
                };
            });
            setAlljobappdata(modifiedData);
        } catch (err) {
            alert(err.message);
        }
    };

    const state = {
        dateContext: selectedOption === 'this month'
            ? moment()
            : moment().subtract(1, 'month'),
        today: moment(),
        selectedDay: null,
    };



    let weekdaysShort = moment.weekdaysShort();

    let daysInMonth = () => {
        return state.dateContext.daysInMonth();
    };


    const currentDay = () => {
        // setTodayDate(state.dateContext.format("D"));

        return state.dateContext.format("D");
    };


    const firstDayOfMonth = () => {
        let dateContext = state.dateContext;
        let firstDay = moment(dateContext).startOf("month").format("d"); // Day of week 0...1..5...6
        return firstDay;
    };



    let weekdayss = weekdaysShort.map((day) => {
        return (
            <td
                key={day}
                className="attendance-calander-heading font-weight500  font-size-heading attendance-calander-heading-td   "
            >
                <div className="attendance-calander-heading  font-weight500 font-size-heading">{day}</div>
            </td>
        );
    });




    let blanks = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
        blanks.push(
            <td key={i * 80} className="emptySlot">
                {""}
            </td>
        );
    }
    let daysInMonths = [];

    for (let d = 1; d <= daysInMonth(); d++) {
        let className = d === currentDay() ? "day current-day" : "day";
        let selectedClass = d === state.selectedDay ? " selected-day " : "";
        let da = d < 10 ? "0" + d : "" + d;
        daysInMonths.push(
            <td
                key={d}
                className={className + selectedClass} style={{
                    padding: "7px 5px",
                    textAlign: "center",
                }}

            >
                <span>
                    <div className="flex-column calander-height font-size-heading">
                        {/* <div>
                            <span className={`attendance-calander-span  font-weight500    font-size-heading ${da === datearray[2] ? "color-red" : ""}`}>
                                {d}
                            </span>
                        </div> */}

                        <div>
                            <span className="attendance-calander-span  font-weight500    font-size-heading" style={da === datearray[2] ? { background: "#2576BC", color: "white", padding: "5px", borderRadius: "50%" } : {}}>
                                {d}
                            </span>
                        </div>

                        <div>
                            {allJobappdatadatap.map((data) => {
                                const inTime = data ? data.in_time : "-";
                                const outTime = data ? data.out_time : "-";
                                const attstat = data ? data.attendence_status : "-";
                                const daystatus = data ? data.day_status : "-";
                                const duration = data ? data.work_duration : "-";
                                let day_status = data ? data.day_status : "-";


                                const day_status_full = data
                                    ? data.day_status === "ontime"
                                        ? "Present"
                                        : data.day_status === "outduty"
                                            ? "Out Duty"
                                            : data.day_status === "halfday"
                                                ? "Half Day"
                                                : data.day_status === "paidleave"
                                                    ? "Paid Leave"
                                                    : data.day_status === "late"
                                                        ? "Late"
                                                        : data.day_status === "weeklyoff"
                                                            ? "Weekly Off"
                                                            : data.day_status === "wop"
                                                                ? "Weekly Off Present"
                                                                : data.day_status === "rlm"
                                                                    ? "Rectified Late Mark"
                                                                    : data.day_status === "halfdayleave"
                                                                        ? "Half Day Leave"
                                                                        : data.day_status === "paidhalfdayleave"
                                                                            ? "Paid Half Day Leave"
                                                                            : data.day_status === "unpaidleave"
                                                                                ? "Unpaid Leave"
                                                                                : data.day_status === "shortleave"
                                                                                    ? "Short Leave"
                                                                                    : data.day_status === "unpaidshortleave"
                                                                                        ? "Unpaid Short Leave"
                                                                                        : data.day_status === "unpaidleavehd"
                                                                                            ? "Unpaid Half Day Leave"
                                                                                            : data.day_status === "hdoutduty"
                                                                                                ? "Half Day Out Duty"
                                                                                                : data.day_status === "shortoutduty"
                                                                                                    ? "Short Out Duty"
                                                                                                    : data.day_status === "misspunch"
                                                                                                        ? "Missed Punch"
                                                                                                        : data.day_status === "holiday"
                                                                                                            ? "Holiday"
                                                                                                            : data.day_status === "absent"
                                                                                                                ? "Absent"
                                                                                                                : data.day_status.slice(0, 2)
                                    : "-";

                                const day_status_short = data
                                    ? data.day_status === "ontime"
                                        ? <span style={{ fontSize: 'small' }}>Present</span> // Present
                                        : data.day_status === "outduty"
                                            ? <span style={{ fontSize: 'small' }}>OutDuty</span> // On Duty
                                            : data.day_status === "halfday"
                                                ? <span style={{ fontSize: 'small' }}>Half Day</span> // Half Day
                                                : data.day_status === "paidleave"
                                                    ? <span style={{ fontSize: 'small' }}>PaidLeave</span> // Paid Leave
                                                    : data.day_status === "late"
                                                        ? <span style={{ fontSize: 'small' }}>Late</span> // Late
                                                        : data.day_status === "weeklyoff"
                                                            ? <span style={{ fontSize: 'small' }}>WeeklyOff</span> // Weekly Off
                                                            : data.day_status === "wop"
                                                                ? <span style={{ fontSize: 'small' }}>WOP</span>
                                                                : data.day_status === "rlm"
                                                                    ? <span style={{ fontSize: 'small' }}>RLM</span> // Restricted Leave Mandatory
                                                                    : data.day_status === "halfdayleave"
                                                                        ? <span style={{ fontSize: 'small' }}>Half Day L</span> // Half Day Leave
                                                                        : data.day_status === "paidhalfdayleave"
                                                                            ? <span style={{ fontSize: 'small' }}>Paid HDL</span> // Paid Half Day Leave
                                                                            : data.day_status === "unpaidleave"
                                                                                ? <span style={{ fontSize: 'small' }}>Unpaid Leave</span> // Unpaid Leave
                                                                                : data.day_status === "shortleave"
                                                                                    ? <span style={{ fontSize: 'small' }}>SL</span> // Short Leave
                                                                                    : data.day_status === "unpaidshortleave"
                                                                                        ? <span style={{ fontSize: 'small' }}>Unpaid SL</span> // Unpaid Short Leave
                                                                                        : data.day_status === "unpaidleavehd"
                                                                                            ? <span style={{ fontSize: 'small' }}>Unpaid HDL</span> // Unpaid Half Day Leave
                                                                                            : data.day_status === "hdoutduty"
                                                                                                ? <span style={{ fontSize: 'small' }}>Half OD</span> // Half Day On Duty
                                                                                                : data.day_status === "shortoutduty"
                                                                                                    ? <span style={{ fontSize: 'small' }}>ShortOD</span> // Short On Duty
                                                                                                    : data.day_status === "misspunch"
                                                                                                        ? <span style={{ fontSize: 'small' }}>Mispunch</span> // Missed Punch
                                                                                                        : data.day_status === "holiday"
                                                                                                            ? <span style={{ fontSize: 'small' }}>Holiday</span> // Holiday
                                                                                                            : data.day_status === "absent"
                                                                                                                ? <span style={{ fontSize: 'small' }}>Absent</span> // Absent
                                                                                                                : <span style={{ fontSize: 'small' }}>{data.day_status.slice(0, 2)}</span>
                                    : <span style={{ fontSize: 'small' }}>-</span>;
                                const isCurrentDate = selectedOption === 'this month' ? da === datearray[2] : (data.date === da && moment().subtract(1, 'month').format('D') === da);
                                return (
                                    <>
                                        {data.date === da ? (
                                            <span
                                                data-status={data.day_status}
                                                className="attendance-status   font-weight500     font-weight500    font-size-label"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span className="progress-profile-percent" title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${day_status_full}\nDuration: ${duration} hrs`}>
                                                    {isCurrentDate ?
                                                        `In-${inTime}` :
                                                        day_status_short
                                                    }
                                                </span>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                );
                            })}

                        </div>
                    </div>
                </span>
            </td >
        );
    }

    var totalSlots = [...blanks, ...daysInMonths];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row);
        } else {
            let insertRow = cells.slice();
            rows.push(insertRow);
            cells = [];
            cells.push(row);
        }
        if (i === totalSlots.length - 1) {
            let insertRow = cells.slice();
            rows.push(insertRow);
        }
    });


    let trElems = rows.map((d, i) => {
        return <tr key={i * 100}>{d}</tr>;
    });



    return (
        <>
            <div className="attendance-calendar-container">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="bgwhite">
                    <div className="employee-detail-edit-svg font-weight600 font-size-heading">
                        {selectedMonth}, {selectedYear}
                    </div>
                    <div className="radialchart-dropdown">
                        <select style={{ border: "none" }} onChange={(e) => { setSelectedOption(e.target.value) }} value={selectedOption}>
                            <option value="this month">This Month</option>
                            <option value="last month">Last Month</option>
                        </select>
                    </div>
                </div>
                <table className="calendar">
                    <tbody className="width-50vw">
                        <tr>{weekdayss}</tr>
                        {trElems}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Calaneder







