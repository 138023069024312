import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, handleAllError, handleErrorToast } from "../../CustomFunctions";
import { DropdownArrowOption, UpdatePencil } from "../../AllSvg";
import usePermission from "../../../config/permissions";

const OnboardNewEmployeeData = ({ data, email, SubCompany }) => {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getDepartment();
        getDesignation();
        getAttendpolicy();
        getWeeklypolicy();
        getReportingHeads();
        getWorksiteList();
        setShow(true)
    };

    const [formData, setFormData] = useState({
        emp_code: "",
        machine_code: "",
        user_detail: email,
        department: "",
        designation: "",
        is_reporthead: "",
        employee_type: "",
        joining_date: "",
        location: "",
        division: sessionStorage.getItem('company_id') || '',
        attendance_policy: "",
        weeklyoff_policy: "",
        employee_status: "active",
        role: '',
        cost_center_assets: "employee",
        show_herarchy: true,
        url: FRONTEND_URL
    });

    const [formDataa, setFormDataa] = useState({
        protal_created: true,
        coordinateby: `${sessionStorage.getItem('emp_code')} - ${sessionStorage.getItem('name')} `,
    });

    // ?  ************** Data Fetch start **************  ? //
    const [buffer1, setBuffering1] = useState(true);
    const [buffer2, setBuffering2] = useState(true);
    const [buffer3, setBuffering3] = useState(true);
    const [buffer4, setBuffering4] = useState(true);
    const [buffer5, setBuffering5] = useState(true);
    const [buffer6, setBuffering6] = useState(true);

    const [departmentname, setDepartmentname] = useState([]);
    const [designationname, setDesignationname] = useState([])
    const [attendpolicy, setAttendpolicy] = useState([]);
    const [weeklypolicy, setWeeklypolicy] = useState([]);
    const [rhname, setRhname] = useState([]);
    const [worksite, setWorksite] = useState([]);

    const getDepartment = async () => {
        try {
            setBuffering1(true)
            const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setDepartmentname(dep.data)
        } catch (err) {
        } finally {
            setBuffering1(false)
        }
    };

    const getDesignation = async () => {
        try {
            setBuffering2(true)
            const des = await axios.get(`${BASE_URL}/wfm/designationfilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setDesignationname(des.data)
        } catch (err) {
        } finally {
            setBuffering2(false)
        }
    };

    const getAttendpolicy = async () => {
        try {
            setBuffering3(true)
            const att = await axios.get(`${BASE_URL}/wfm/attendancepolicyfilterall/${formData.division || 'null'}/active/`);
            setAttendpolicy(att.data);
        } catch (err) {
        } finally {
            setBuffering3(false)
        }
    };

    const getWeeklypolicy = async () => {
        try {
            setBuffering4(true)
            const week = await axios.get(`${BASE_URL}/wfm/weeklyofffilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setWeeklypolicy(week.data);
        } catch (err) {
        } finally {
            setBuffering4(false)
        }
    };

    const getWorksiteList = async () => {
        try {
            setBuffering5(true)
            const res = await axios.get(`${BASE_URL}/wfm/worksitefilterbysubcompanycount/${formData.division || 'null'}/active/`);
            setWorksite(res.data);
        } catch (err) {
        } finally {
            setBuffering5(false)
        }
    };

    const getReportingHeads = async () => {
        try {
            setBuffering6(true)
            const res = await axios.get(`${BASE_URL}/wfm/rhlistactive/null/active/`);
            setRhname(res.data);
        } catch (err) {
        } finally {
            setBuffering6(false)
        }
    };

    // !  ************** Data Fetch end **************  ! //

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "emp_code",
            "department",
            'machine_code',
            "designation",
            "is_reporthead",
            "employee_type",
            "joining_date",
            "location",
            "division",
            "attendance_policy",
            "weeklyoff_policy",
            'role'
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        if (name === "division") {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                department: "",
                designation: "",
                attendance_policy: "",
                weeklyoff_policy: "",
                location: "",
                reporting_head: ""
            }));
            getDepartment(value);
            getDesignation(value);
            getAttendpolicy(value);
            getWeeklypolicy(value);
            getWorksiteList(value);
            // getReportingHeads(value);
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic 
            try {
                let res = await axios.post(`${BASE_URL}/wfm/ourcompanyuserdetail/`, formData,)

                if (res.status === 200) {

                    let res1 = await axios.put(
                        `${BASE_URL}/jd/jobapplicant/${data.id}/`,
                        formDataa
                    );
                    let res2 = await axios.put(
                        `${BASE_URL}/user/userupdated/${email}/`
                    );

                    if (res1.status === 200 && res2.status === 200) {
                        toast.done('Done processing your request.')
                        window.location.reload();
                    }
                } else {
                    alert(res);
                }
            } catch (err) {
                handleAllError(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };
    const getMaxDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    };

    return (
        <>
            <button title="Onboard New Employee" className="model-edit-button" onClick={handleShow} >
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>Onboarding New Employee : "{data.name} - {data.user_detail}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="register-form">
                            <div className="form-flex-wrap">

                                <div title="Emp Code" className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels font-weight500 font-size-subheading">
                                        Emp Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message font-size-text ">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div title="Machine Code" className="flex-column">
                                    <label htmlFor="machine_code" className="form-labels font-weight500 font-size-subheading">
                                        Machine Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="machine_code"
                                        name="machine_code"
                                        placeholder="Machine Code"
                                        onChange={handleInputChange}
                                        value={formData.machine_code}
                                        className={`form-input ${errors.machine_code
                                            ? "error"
                                            : inputState.machine_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.machine_code && (
                                        <span className="error-message font-size-text ">{errors.machine_code}</span>
                                    )}
                                </div>

                                <div title="Joining Date" className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels font-weight500 font-size-subheading">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                                </div>

                                <div title="Sub Company List" className="flex-column form-group-selectt">
                                    <label htmlFor="division" className="form-labels font-weight500 font-size-subheading">
                                        Division<span className="required">*</span>
                                    </label>
                                    <br />

                                    <select
                                        id="division"
                                        name="division"
                                        value={formData.division}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.division ? "error" : inputState.division ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Division</option>
                                        {SubCompany?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))}
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.division && (
                                            <span className="error-message font-size-text ">{errors.division}</span>
                                        )}
                                    </div>

                                </div>

                                <div title="Departments List" className="flex-column form-group-selectt">
                                    <label htmlFor="department" className="form-labels font-weight500 font-size-subheading">
                                        Department<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer1 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="department"
                                                name="department"
                                                value={formData.department}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Department</option>
                                                {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.department && (
                                                    <span className="error-message font-size-text ">{errors.department}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Designations List" className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels font-weight500 font-size-subheading">
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer2 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="designation"
                                                name="designation"
                                                value={formData.designation}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Designation</option>
                                                {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.designation && (
                                                    <span className="error-message font-size-text ">{errors.designation}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Attendance Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="attendance_policy" className="form-labels font-weight500 font-size-subheading">
                                        Attendance Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer3 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="attendance_policy"
                                                name="attendance_policy"
                                                value={formData.attendance_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.attendance_policy ? "error" : inputState.attendance_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Attendance Policy</option>
                                                {attendpolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}-{i.sub_company_title}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.attendance_policy && (
                                                    <span className="error-message font-size-text ">{errors.attendance_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="WeeklyOff Policy List" className="flex-column form-group-selectt">
                                    <label htmlFor="weeklyoff_policy" className="form-labels font-weight500 font-size-subheading">
                                        WeeklyOff Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer4 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="weeklyoff_policy"
                                                name="weeklyoff_policy"
                                                value={formData.weeklyoff_policy}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.weeklyoff_policy ? "error" : inputState.weeklyoff_policy ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Weeklyoff Policy</option>
                                                {weeklypolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.name}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.weeklyoff_policy && (
                                                    <span className="error-message font-size-text ">{errors.weeklyoff_policy}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Work Location List" className="flex-column form-group-selectt">
                                    <label htmlFor="location" className="form-labels font-weight500 font-size-subheading">
                                        Work Site<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer5 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.location ? "error" : inputState.location ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a location</option>
                                                {worksite?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}-{i.sub_company_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.location && (
                                                    <span className="error-message font-size-text ">{errors.location}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Reporting Heads List" className="flex-column form-group-selectt">
                                    <label htmlFor="reporting_head" className="form-labels font-weight500 font-size-subheading">
                                        Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer6 ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="reporting_head"
                                                name="reporting_head"
                                                value={formData.reporting_head}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.reporting_head ? "error" : inputState.reporting_head ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Reporting Head</option>
                                                {rhname?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.user_detail}>{i.name}-{i.division_name}</option>
                                                </>))}
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.reporting_head && (
                                                    <span className="error-message font-size-text ">{errors.reporting_head}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div title="Is Reporting Head" className="flex-column form-group-selectt">
                                    <label htmlFor="is_reporthead" className="form-labels font-weight500 font-size-subheading">
                                        Is Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="is_reporthead"
                                        name="is_reporthead"
                                        value={formData.is_reporthead}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.is_reporthead ? "error" : inputState.is_reporthead ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="True">Yes</option>
                                        <option value="False">No</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.is_reporthead && (
                                            <span className="error-message font-size-text ">{errors.is_reporthead}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Dashboard Employee Roles" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Role<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.role ? "error" : inputState.role ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Role</option>
                                        <option value="Employee">Employee</option>
                                        <option value="ReportingHead">Team Lead</option>
                                        <option value="HOD">HOD</option>
                                        <option value="HR">HR</option>
                                        <option value="Admin">Admin</option>
                                        <option value="IT">IT</option>
                                        <option value="Account">Accounts</option>
                                        <option value="SiteManagement">Site Management</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.role && (
                                            <span className="error-message font-size-text ">{errors.role}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="Employee Type" className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee_type"
                                        name="employee_type"
                                        value={formData.employee_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Employee</option>
                                        <option value="permanent">Permanent</option>
                                        <option value="temporary">Temporary</option>
                                        <option value="probation">Probation</option>
                                        <option value="termination">Termination</option>
                                        <option value="resigned">Resigned</option>
                                        <option value="absconding">Absconding</option>
                                        <option value="healthissue">Healthissue</option>
                                        <option value="intern">Intern</option>
                                        <option value="trainee">Trainee</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.employee_type && (
                                            <span className="error-message font-size-text ">{errors.employee_type}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-column ">
                                <div className="flex-row ">
                                    <div className="checkbox-toggle">
                                        <input
                                            type="checkbox"
                                            id="toggle_show_herarchy"
                                            name="show_herarchy"
                                            checked={formData.show_herarchy}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="toggle_show_herarchy"></label>
                                    </div>
                                    <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                    <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                                        {formData.show_herarchy ? "View" : "Hide"} in Hierarchy {" "}
                                    </label>
                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleFormSubmit} className="model-button font-weight500 model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>
                            </div>
                        </form ></>

                </Modal.Body >
            </Modal >
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

export { OnboardNewEmployeeData };
