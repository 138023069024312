
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { handleAllError } from '../CustomFunctions';
import { formattedDate } from '../Date';
import { CheckGreen } from '../AllSvg';

const ApplyAdvanceForm = ({ code }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: "",
      department: sessionStorage.getItem("department"),
      rh_assigned: sessionStorage.getItem("rh"),
      advance_amount: "",
      reason_for_taking_advance: "",
      date_of_request: formattedDate,
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: code || sessionStorage.getItem("emp_code"),
      department: sessionStorage.getItem("department"),
      rh_assigned: sessionStorage.getItem("rh"),
      advance_amount: "",
      reason_for_taking_advance: "",
      date_of_request: formattedDate,
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: code || sessionStorage.getItem("emp_code"),
    department: sessionStorage.getItem("department"),
    advance_amount: "",
    reason_for_taking_advance: "",
    date_of_request: formattedDate,
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "department",
      "rh_assigned",
      "advance_amount",
      "reason_for_taking_advance",
      "date_of_request",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/advanceforms/`,
          formData
        );

        if (res.status === 200) {
          // await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}`;
  };

  return (
    <>
      <button title="Apply Advance Form" className="model-view-button model-add" onClick={handleShow}>
        {/* <AddwithBlueCircle /> */}
        <svg
          width="24"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          id="savings-account"
        >
          <path d="M10.5 19h5.5c.55 0 1-.45 1-1s-.45-1-1-1h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.5c-.55 0-1 .45-1 1s.45 1 1 1h3v2c0 .55.45 1 1 1s1-.45 1-1v-2h.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zM31 16h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 22h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 28h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          <path d="m38,37c2.21,0,4-1.79,4-4v-4.37c1.82.87,3.85,1.37,6,1.37,7.72,0,14-6.28,14-14s-6.28-14-14-14c-5.17,0-9.68,2.82-12.11,7H6c-2.21,0-4,1.79-4,4v20c0,2.21,1.79,4,4,4h13v13h-3c-.55,0-1,.45-1,1v7c0,2.21,1.79,4,4,4h34c2.21,0,4-1.79,4-4v-27c0-.55-.45-1-1-1s-1,.45-1,1v27c0,1.1-.9,2-2,2h-2c-1.1,0-2-.9-2-2v-7c0-.55-.45-1-1-1h-27v-13h17Zm3.29-20.71c.39-.39,1.02-.39,1.41,0l3.25,3.25,7.3-8.21c.37-.41,1-.45,1.41-.08.41.37.45,1,.08,1.41l-8,9c-.18.21-.44.33-.72.33,0,0-.02,0-.03,0-.26,0-.52-.1-.71-.29l-4-4c-.39-.39-.39-1.02,0-1.41ZM6,35c-1.1,0-2-.9-2-2V13c0-1.1.9-2,2-2h28.94c-.6,1.56-.94,3.24-.94,5,0,4.75,2.38,8.94,6,11.47v5.53c0,1.1-.9,2-2,2H6Z"></path>
          <path d="M48 40h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1zM48 45h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Apply for Advance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label
                  htmlFor="advance_amount"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount<span className="required">*</span>
                </label>
                <input
                  id="advance_amount"
                  type="number"
                  name="advance_amount"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.advance_amount}
                  className={`form-input ${errors.advance_amount
                    ? "error"
                    : inputState.advance_amount
                      ? "success"
                      : ""
                    }`}
                />
                {errors.advance_amount && (
                  <span className="error-message font-size-text ">{errors.advance_amount}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="reason_for_taking_advance" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Taking Advance<span className="required">*</span>
                </label>
                <textarea
                  id="reason_for_taking_advance"
                  type="text"
                  name="reason_for_taking_advance"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.reason_for_taking_advance}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reason_for_taking_advance
                    ? "error"
                    : inputState.reason_for_taking_advance
                      ? "success"
                      : ""
                    }`}
                />
                {errors.reason_for_taking_advance && (
                  <span className="error-message font-size-text ">{errors.reason_for_taking_advance}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHODApproval = ({ i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      advance: "",

      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      advance: "approved",

      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    advance: "approved",

    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "advance",
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/advanceforms/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          // await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}`;
  };

  return (
    <>
      <button title="HOD Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HOD Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="reason_for_taking_advance" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for taking advance<span className="required">*</span>
                </label>
                <textarea
                  id="reason_for_taking_advance"
                  type="text"
                  name="reason_for_taking_advance"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.reason_for_taking_advance}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reason_for_taking_advance
                    ? "error"
                    : inputState.reason_for_taking_advance
                      ? "success"
                      : ""
                    }`}
                />
                {errors.reason_for_taking_advance && (
                  <span className="error-message font-size-text ">{errors.reason_for_taking_advance}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHRApproval = ({ }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: "",
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: "",
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    number_of_installments: "",
    installment_start_month: "",
    installment_end_month: "",
    total_amount_claimed: "",

    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "number_of_installments",
      "installment_start_month",
      "installment_end_month",
      "total_amount_claimed",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/imprest/`,
          updatedFormData
        );

        if (res.status === 200) {
          // await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}`;
  };

  return (
    <>
      <button title="HR Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>


      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HR Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label
                  htmlFor="number_of_installments"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number of Installments<span className="required">*</span>
                </label>
                <input
                  id="number_of_installments"
                  type="number"
                  name="number_of_installments"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.number_of_installments}
                  className={`form-input ${errors.number_of_installments
                    ? "error"
                    : inputState.number_of_installments
                      ? "success"
                      : ""
                    }`}
                />
                {errors.number_of_installments && (
                  <span className="error-message font-size-text ">{errors.number_of_installments}</span>
                )}
              </div>
              <div className="flex-row multi-day-input">
                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">Start Month<span className="required">*</span></label>
                  <input
                    id="installment_start_month"
                    name="installment_start_month"
                    type="month"
                    // min={getCurrentMonth()}
                    max={formData.installment_end_month}
                    onWheel={(e) => e.target.blur()}
                    onChange={handleInputChange}
                    value={formData.installment_start_month}
                    className={`multi-day-input1 form-input-background ${errors.installment_start_month
                      ? "error"
                      : inputState.installment_start_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_start_month && (
                    <span className="error-message font-size-text ">{errors.installment_start_month}</span>
                  )}
                </div>
                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">End Month<span className="required">*</span></label>
                  <input
                    id="installment_end_month"
                    name="installment_end_month"
                    type="month"
                    min={formData.installment_start_month}
                    onWheel={(e) => e.target.blur()}
                    value={formData.installment_end_month}
                    onChange={handleInputChange}
                    className={`multi-day-input1 form-input-background ${errors.installment_end_month
                      ? "error"
                      : inputState.installment_end_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_end_month && (
                    <span className="error-message font-size-text ">{errors.installment_end_month}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label
                  htmlFor="total_amount_claimed"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Total Amount to Claim<span className="required">*</span>
                </label>
                <input
                  id="total_amount_claimed"
                  type="number"
                  name="total_amount_claimed"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.total_amount_claimed}
                  className={`form-input ${errors.total_amount_claimed
                    ? "error"
                    : inputState.total_amount_claimed
                      ? "success"
                      : ""
                    }`}
                />
                {errors.total_amount_claimed && (
                  <span className="error-message font-size-text ">{errors.total_amount_claimed}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500 model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormModal = ({ }) => {
  const [show, setShow] = useState(false);
  const [advanceToggle, setAdvanceToggle] = useState(false);
  const handleClose = () => {
    setFormData({
      empcode: "",
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: "",
      reason_for_taking_advance: "",
      date_of_request: formattedDate,
      advance: false,
    })
    setAdvanceToggle(false)
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      empcode: sessionStorage.getItem("emp_code"),
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: "",
      reason_for_taking_advance: "",
      advance: advanceToggle,
    })
    setAdvanceToggle(false)
    setShow(true)
  };

  const [formData, setFormData] = useState({
    empcode: sessionStorage.getItem("emp_code"),
    number_of_installments: "",
    installment_start_month: "",
    installment_end_month: "",
    total_amount_claimed: "",
    reason_for_taking_advance: "",
    advance: advanceToggle,
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "empcode",
      "number_of_installments",
      "installment_start_month",
      "installment_end_month",
      "total_amount_claimed",
      "reason_for_taking_advance",
      "total_amount_claimed",
      // "advance",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      advance: advanceToggle
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/imprest/`,
          updatedFormData
        );

        if (res.status === 200) {
          // await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}`;
  };

  return (
    <>
      <button title="Add Imperest" className="model-view-button model-add" onClick={handleShow}>
        {/* <AddwithBlueCircle /> */}
        <svg
          width="24"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          id="savings-account"
        >
          <path d="M10.5 19h5.5c.55 0 1-.45 1-1s-.45-1-1-1h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.5c-.55 0-1 .45-1 1s.45 1 1 1h3v2c0 .55.45 1 1 1s1-.45 1-1v-2h.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zM31 16h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 22h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 28h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          <path d="m38,37c2.21,0,4-1.79,4-4v-4.37c1.82.87,3.85,1.37,6,1.37,7.72,0,14-6.28,14-14s-6.28-14-14-14c-5.17,0-9.68,2.82-12.11,7H6c-2.21,0-4,1.79-4,4v20c0,2.21,1.79,4,4,4h13v13h-3c-.55,0-1,.45-1,1v7c0,2.21,1.79,4,4,4h34c2.21,0,4-1.79,4-4v-27c0-.55-.45-1-1-1s-1,.45-1,1v27c0,1.1-.9,2-2,2h-2c-1.1,0-2-.9-2-2v-7c0-.55-.45-1-1-1h-27v-13h17Zm3.29-20.71c.39-.39,1.02-.39,1.41,0l3.25,3.25,7.3-8.21c.37-.41,1-.45,1.41-.08.41.37.45,1,.08,1.41l-8,9c-.18.21-.44.33-.72.33,0,0-.02,0-.03,0-.26,0-.52-.1-.71-.29l-4-4c-.39-.39-.39-1.02,0-1.41ZM6,35c-1.1,0-2-.9-2-2V13c0-1.1.9-2,2-2h28.94c-.6,1.56-.94,3.24-.94,5,0,4.75,2.38,8.94,6,11.47v5.53c0,1.1-.9,2-2,2H6Z"></path>
          <path d="M48 40h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1zM48 45h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Advance Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label
                  htmlFor="number_of_installments"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number of Installments<span className="required">*</span>
                </label>
                <input
                  id="number_of_installments"
                  type="number"
                  name="number_of_installments"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.number_of_installments}
                  className={`form-input ${errors.number_of_installments
                    ? "error"
                    : inputState.number_of_installments
                      ? "success"
                      : ""
                    }`}
                />
                {errors.number_of_installments && (
                  <span className="error-message font-size-text ">{errors.number_of_installments}</span>
                )}
              </div>
              <div className="flex-row multi-day-input">
                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">Start Month<span className="required">*</span></label>
                  <input
                    id="installment_start_month"
                    name="installment_start_month"
                    type="month"
                    // min={getCurrentMonth()}
                    max={formData.installment_end_month}
                    onWheel={(e) => e.target.blur()}
                    onChange={handleInputChange}
                    value={formData.installment_start_month}
                    className={`multi-day-input1 form-input-background ${errors.installment_start_month
                      ? "error"
                      : inputState.installment_start_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_start_month && (
                    <span className="error-message font-size-text ">{errors.installment_start_month}</span>
                  )}
                </div>
                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">End Month<span className="required">*</span></label>
                  <input
                    id="installment_end_month"
                    name="installment_end_month"
                    type="month"
                    min={formData.installment_start_month}
                    onWheel={(e) => e.target.blur()}
                    value={formData.installment_end_month}
                    onChange={handleInputChange}
                    className={`multi-day-input1 form-input-background ${errors.installment_end_month
                      ? "error"
                      : inputState.installment_end_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_end_month && (
                    <span className="error-message font-size-text ">{errors.installment_end_month}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label
                  htmlFor="total_amount_claimed"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Total Amount to Claim<span className="required">*</span>
                </label>
                <input
                  id="total_amount_claimed"
                  type="number"
                  name="total_amount_claimed"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.total_amount_claimed}
                  className={`form-input ${errors.total_amount_claimed
                    ? "error"
                    : inputState.total_amount_claimed
                      ? "success"
                      : ""
                    }`}
                />
                {errors.total_amount_claimed && (
                  <span className="error-message font-size-text ">{errors.total_amount_claimed}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="reason_for_taking_advance" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for taking advance<span className="required">*</span>
                </label>
                <textarea
                  id="reason_for_taking_advance"
                  type="text"
                  name="reason_for_taking_advance"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.reason_for_taking_advance}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reason_for_taking_advance
                    ? "error"
                    : inputState.reason_for_taking_advance
                      ? "success"
                      : ""
                    }`}
                />
                {errors.reason_for_taking_advance && (
                  <span className="error-message font-size-text ">{errors.reason_for_taking_advance}</span>
                )}
              </div>

              <div className="flex-row ">
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  Advance : {" "}
                </label>
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={advanceToggle}
                    onChange={() => setAdvanceToggle(!advanceToggle)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const EmployeeAdvanceSlipsTable = ({ title, email, emp_code }) => {

  const [formData, setUserData] = useState({})
  const [allrequest, setAllRequest] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getUSerDetails = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`);
      setUserData(res.data);

    } catch (err) {
      handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getAllrequest = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/advanceformsbyemployee/${emp_code}/`);
      setAllRequest(res.data);
    } catch (err) {
      //   handleError
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  console.log(allrequest);
  useEffect(() => {
    getUSerDetails();
    getAllrequest();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500 font-size-heading"> Advance Slip Request</div>
          <div>
            {formData.advance_form ?
              <div className="field-cont">
                Apply for Advance:
                <ApplyAdvanceForm
                  code={emp_code}
                />
              </div>
              : null}
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr no.</th>
              <th className="align-leftt">Date</th>
              <th className="align-center">Type</th>
              <th className="align-center">Duration</th>
              <th className="align-center width-10vw">Reason</th>
              <th className="align-center">Status</th>
              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> :
            <tbody>
              {allrequest
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="9"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-leftt">
                        {/* {formatDate(i.date)} &<br /> {formatDate(i.end_date)} */}
                      </td>
                      <td className="align-center">
                        {i.leave_Duration ? "Leave" : ""}{" "}
                        {i.outduty_Duration ? "OutDuty" : ""}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.leave_Duration ? i.leave_Duration : i.outduty_Duration}
                      </td>
                      <td className="align-center width-10vw">{i.remarks}</td>

                      <td className="align-right width-5vw ">
                        {i.rh_leave_status === "pending" ? (
                          // <OutdutyModelDelete
                          //   i={i}
                          //   getAllrequest={getAllrequest}
                          // />
                          <></>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

export { ApplyAdvanceForm, AdvanceFormHODApproval, AdvanceFormHRApproval, EmployeeAdvanceSlipsTable };