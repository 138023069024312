import React, { useState } from 'react';
import { MyTickets, RaisedTickets } from './TicketsTables';
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import usePermission from '../../config/permissions';

const TicketsManagement = () => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY, SUPER_ADMIN } = usePermission(); // Use the custom hook

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'My Tickets';
            case 2:
                return 'Raised Tickets';
            default:
                return 'My Tickets';
        }
    };

    return (
        <>
            <Dashboardnavbarcopy name={getSelectedTitle()} url="Tickets Managment" />
            <div >
                {sessionStorage.getItem('department') === 'b3e3afd9-97e4-402f-85eb-4c22599ee5e8' ?
                    <div className="bloc-tabss2  wfm-margin">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(1)}
                        >
                            My Tickets
                        </button>
                        < button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(2)}
                        >
                            Raised Tickets
                        </button>
                    </div>
                    : null
                }
            </div >
            <div className="content-tabs">
                {toggleState === 1 ? <MyTickets /> : null}
                {toggleState === 2 ? <RaisedTickets /> : null}
            </div>
        </>
    );
};

export default TicketsManagement;