import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { customSortByKey, handleAllError } from "../CustomFunctions";
import { Arrow, Collapse, DropdownArrow, DropdownArrowClosed, DropdownArrowOption, Employee, Expand, SearchBig, Sort } from "../AllSvg";

const NewHierarchyChain = () => {
  const [treeData, setTreeData] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [targetEmpCode, setTargetEmpCode] = useState(sessionStorage.getItem("emp_code"));
  const userRefs = useRef({});


  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleShowMyself = () => {
    const emp_code = sessionStorage.getItem('emp_code');
    console.log(emp_code)
    handleLocateClick(emp_code)
  };

  const closeSearchBox = () => {
    setShowSearchBox(false);
    setSearchValue('');
    setSearchResults([]);
  };



  const findInTree = (root, searchText) => {
    const results = [];
    const regex = new RegExp(searchText, 'i');
    const search = (node) => {
      if (node && node.user_detail) {
        if (regex.test(node.emp_code) || regex.test(node.user_detail.name) || regex.test(node.user_detail.employee)) {
          results.push(node);
        }
        if (Array.isArray(node.subordinates)) {
          node.subordinates.forEach(search);
        }
      }
    };

    search(root);
    return results;
  };


  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.length >= 3) {
      const results = findInTree(data[0], searchTerm);
      console.log("results")
      console.log(results)
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };


  const renderSearchResults = () => {

    return searchResults.map((result) => (
      <div className="list-item" key={result.emp_code}>
        <div className="image-wrapper">
          <img
            src={result.profilepic ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${result.profilepic}`
              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
            alt={result.user_detail.name}
            className="image"
          />
        </div>
        <div className="description">
          <p className="name">{result.user_detail.name}-{result.emp_code}</p>
          <p className="position-name">{result.designation_name}</p>
          <p className="area">{result.department_name}</p>
        </div>
        <div className="buttons">
          <button className="btn-action btn-search-box" onClick={() => handleLocateClick(result?.emp_code)}>Locate</button>
        </div>
      </div>
    ));
  };

  const findUserAndExpand = (data, targetCode, expandedNodesSet) => {
    // console.log(data, 'data')
    for (let i = 0; i < data.length; i++) {
      const user = data[i];
      expandedNodesSet.add(user.emp_code);
      if (user.emp_code === targetCode) {
        return true;
      }

      if (user.subordinates && user.subordinates.length > 0) {
        const foundInSubordinates = findUserAndExpand(user.subordinates, targetCode, expandedNodesSet);
        if (foundInSubordinates) {
          return true;
        }
      }
      expandedNodesSet.delete(user.emp_code);
    }
    return false;
  };

  const [data, setData] = useState([]);
  const getOrgHierarchyData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/wfm/herasubcomp/${sessionStorage.getItem('company_id')}/`
      );
      setData(response.data);
      const transformedData = response.data;
      setTreeData([transformedData]);
      const expandedNodesSet = new Set();
      const pathFound = findUserAndExpand(transformedData, targetEmpCode, expandedNodesSet);
      // console.log(pathFound, 'pathfound')
      if (pathFound) {
        setExpandedNodes(expandedNodesSet);
      } else {
        setExpandedNodes(new Set());
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      handleAllError(error)
    }
  };

  useEffect(() => {
    getOrgHierarchyData();
  }, []);

  const handleLocateClick = (empCode) => {
    const expandedNodesSet = new Set();
    const cleanData = treeData.length > 0 ? treeData[0] : null;

    if (cleanData) {
      const pathFound = findUserAndExpand(cleanData, empCode, expandedNodesSet);
      console.log(pathFound, 'pathfound');

      if (pathFound) {
        setExpandedNodes(expandedNodesSet);

        // Wait for React to render the updated nodes
        setTimeout(() => {
          const targetNode = userRefs.current[empCode];
          if (targetNode) {
            targetNode.scrollIntoView({ behavior: "smooth", block: "center" });
          } else {
            console.warn(`Target node not found for empCode: ${empCode}`);
          }
        }, 100); // Increased timeout for deeper rendering
      } else {
        console.warn(`Path not found for empCode: ${empCode}`);
      }
    }
  };

  const UserNode = ({ user, showordinate }) => {
    const [showSubordinates, setShowSubordinates] = useState(false);
    const toggleSubordinates = () => {
      setShowSubordinates(!showSubordinates);
    };
    useEffect(() => {
      if (expandedNodes.has(user[0].emp_code)) {
        // if (user[0].emp_code !== targetEmpCode) {
        //   setShowSubordinates(true);
        // }
        setShowSubordinates(true);
      }
    }, [user[0].emp_code, expandedNodes])

    return (
      <div >
        <div
          className="main-hireachy-div"
        >
          <div
            className="hireachry-user-box"
          >
            <div
              onClick={toggleSubordinates}
              className="hireachry-user-details"
            >
              <span
                className="hireachy-user-image"
              >
                <img src={
                  user[0].profilepic ?
                    `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${user[0].profilepic}`
                    : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                  alt="user-image" style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                />
              </span>
              <br />
              <h2>{user[0].user_detail.name}</h2>
              <h5>{user[0].emp_code}</h5>
              <p className="font-weight400">
                {user[0].department_name}
                <br />
                {user[0].designation_name}
              </p>
              <div
                ref={(el) => (userRefs.current[user[0].emp_code] = el)}
                className="user-scroll"
              ></div>
              <button
                onClick={toggleSubordinates}
                style={{
                  backgroundColor: showSubordinates ? "rgb(255, 116, 124);" : '#2576bc'

                }}
                className="hireachy-toggle-button font-weight500"
              >
                {showSubordinates ?
                  <div className="flex-row justify-between">
                    {user[0].subordinates.length}
                    <hr />
                    <DropdownArrowOption />
                  </div>
                  :
                  <div className="flex-row justify-between">
                    {user[0].subordinates.length}
                    <hr />
                    <DropdownArrowClosed />
                  </div>
                }
              </button>
            </div>
          </div>
          {showSubordinates && user[0].subordinates.length > 0 && (
            <div className="hireachy-subordinate-box flex-row">
              <span className="hireachy-branch-info-top" onClick={toggleSubordinates}>
                Reportings to {user[0]?.user_detail?.name}
              </span>
              <span className="hireachy-collapse-button flex-row" onClick={toggleSubordinates}>
                collapse
                <Collapse />
              </span>
              <span className="hireachy-branch-info-bottom" onClick={toggleSubordinates}>
                Reportings to {user[0]?.user_detail?.name}
              </span>
              {customSortByKey(user[0].subordinates, "emp_code").map((sub) => (
                <UserNode key={sub.emp_code} user={[sub]} showordinate={false} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };


  return (
    <div id="full-container" className={isFullScreen ? 'full-screen' : ''}>
      <div className="flex-row">
        {/* <button className="btn-action btn-fullscreen flex-row" onClick={toggleFullScreen}>
          FullScreen <Expand color={"white"} />
        </button> */}
        <button className="btn-action btn-show-my-self" onClick={handleShowMyself}>
          Show Myself <Employee color={"white"} />
        </button>
        <button className="btn-action btn-search" onClick={() => setShowSearchBox(!showSearchBox)}>
          Search <SearchBig color={"white"} />
        </button>
        <button className="btn-action btn-back" onClick={() => setSelectedEmployee(null)} >
          Back
        </button>
      </div>

      {showSearchBox && (
        <div className="user-search-box open">
          <div className=" scrollfix-wrapper">
            <div
              className="scroll-fix-table-heading input-box ">
              <div className="close-button-wrapper" onClick={closeSearchBox}>
                <Arrow color={"white"} />
              </div>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearch}
                />
                <br />
                <div className="input-bottom-placeholder">By Name/Code/Email</div>
              </div>
            </div>
            <div className="scroll-fix-table">
              <div className="result-box scrollfix-wrapper">
                <div className="result-header">RESULTS</div>
                <div className="result-list ">{renderSearchResults()}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hierarchy-container"
        style={{ zoom: '75%' }}
      >
        {treeData.length > 0 ? (
          treeData?.map((user) => (
            <UserNode key={user.emp_code} user={user} showordinate={true} />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default NewHierarchyChain;