import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config/axios";
import { customSortByKey, handleAllError } from "../CustomFunctions";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { BlueEye, SearchSmall } from "../AllSvg";



const EmployeesforDashboardData = ({ data, title, subcompany, empstatus, emptype, month, year }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getDashboardEmployees();
  };

  const [dashboardEmployees, setDashboardEmployees] = useState([]);

  const getDashboardEmployees = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      // const res = await axios.get(
      //   // `${BASE_URL}/wfm/showemployeedata/${subcompany || 'null'}/${empstatus || 'null'}/${emptype || 'null'}/${month || 'null'}/${year || 'null'}/`
      //   `${BASE_URL}/wfm/employeestatusbysubcompanyv3/${month || 'null'}/${year || 'null'}/${subcompany || 'null'}/`
      // );
      // const sortedList = customSortByKey(res.data, "emp_code");

      // setDashboardEmployees(sortedList);
      setDashboardEmployees([]);
    } catch (err) {
      handleAllError(err);
    } finally {
      setBuffering(false);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]

  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        <BlueEye />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  {/* Employees for : <br /> */}
                  {title} ({data?.filter(
                    (e) =>
                      e.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      e.emp_code
                        .toString()
                        .includes(searchQuery.toLowerCase())
                  ).length}/{data.length})
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <SearchSmall />
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {data
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeeforDashboardDataOnboarding = ({ title, subcompany, month, year }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getDashboardEmployees();
  };

  const [dashboardEmployees, setDashboardEmployees] = useState([]);

  const getDashboardEmployees = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/onbordingemployees/${subcompany || 'null'}/${month || 'null'}/${year || 'null'}`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setDashboardEmployees(sortedList);
    } catch (err) {
      handleAllError(err);
    } finally {
      setBuffering(false);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[3]

  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        <BlueEye />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  {/* Employees for : <br /> */}
                  {title} ({dashboardEmployees?.filter(
                    (e) =>
                      e.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      e.emp_code
                        .toString()
                        .includes(searchQuery.toLowerCase())
                  ).length}/{dashboardEmployees.length})
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <SearchSmall />
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {dashboardEmployees
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>

                            <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeeData = () => {

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');


  let previousYear = currentYear;
  let previousMonth = today.getMonth();




  if (previousMonth === 0) {
    previousYear--;
    previousMonth = 12;
  }


  const [selectedOption, setSelectedOption] = useState('this month');

  const [empData, setEmpData] = useState({});
  const [empTableData, setEmpTableData] = useState({});

  const getJobapppdata = async (selectedOption) => {
    try {
      const res = await axios.get(
        selectedOption === "this month"
          // ? `${BASE_URL}/wfm/employeestatus/${currentYear}/${currentMonth}/${sessionStorage.getItem('company_id')}/`
          // : `${BASE_URL}/wfm/employeestatus/${previousYear}/${previousMonth}/${sessionStorage.getItem('company_id')}/`
          ? `${BASE_URL}/wfm/employeestatusbysubcompany/${currentMonth}/${currentYear}/${sessionStorage.getItem('company_id')}/`
          : `${BASE_URL}/wfm/employeestatusbysubcompany/${previousMonth}/${previousYear}/${sessionStorage.getItem('company_id')}/`
        // ? `${BASE_URL}/wfm/employeestatus/${currentYear}/${currentMonth}/`
        // : `${BASE_URL}/wfm/employeestatus/${previousYear}/${previousMonth}/`
      );

      const response = await axios.get(
        selectedOption === "this month"
          ? `${BASE_URL}/wfm/employeebasicinfodashbord/${currentMonth}/${currentYear}/${sessionStorage.getItem('company_id')}/`
          : `${BASE_URL}/wfm/employeebasicinfodashbord/${previousMonth}/${previousYear}/${sessionStorage.getItem('company_id')}/`
      );

      setEmpData(res.data);
      setEmpTableData(response.data);
    } catch (err) {
      // alert(err.message);
      // handleAllError(err)
      // console.log(err)
    }
  };

  useEffect(() => {
    getJobapppdata(selectedOption);
  }, [selectedOption]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };



  return (
    <>
      <div className="empDataa">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="repo-heading-a font-weight500   font-size-subheading">Employees data</div>
          <div className="radialchart-dropdown">
            <select style={{ border: "none" }} onChange={handleChange} value={selectedOption}>
              <option value="this month">This Month</option>
              <option value="last month">Last Month</option>
            </select>
          </div>
        </div>

        <div className="empData-felx">
          <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`Total Employees ${selectedOption}`} className="empData-tab width-15vw Color-tab-blue">
                {/* <div className="empData-Tab-heading">Total Employees</div> */}
                <div className="flex-row justify-between">
                  <div className="empData-Tab-heading">Total Employees
                  </div>
                  {/* <EmployeesforDashboardData
                    data={customSortByKey(empTableData?.total_employees, "emp_code")}
                    title={"Total Employees"}
                    subcompany={sessionStorage.getItem('company_id')}
                    empstatus={"active"}
                    month={selectedOption === "this month" ? currentMonth : previousMonth}
                    year={selectedOption === "this month" ? currentYear : previousYear}
                  /> */}
                </div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.total_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`Active Employees in ${selectedOption}`} className="empData-tab width-15vw Color-tab-green">
                {/* <div className="empData-Tab-heading">Active Employees</div> */}
                <div className="flex-row justify-between">
                  <div className="empData-Tab-heading">Active Employees
                  </div>
                  {/* <EmployeesforDashboardData
                    data={customSortByKey(empTableData?.active_employees, "emp_code")}
                    title={"Active Employees"}
                    subcompany={sessionStorage.getItem('company_id')}
                    empstatus={"active"}
                  /> */}
                </div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.active_employees_count - empData.joined_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`New Joinings in ${selectedOption}`} className="empData-tab width-15vw Color-tab-teal">
                {/* <div className="empData-Tab-heading">Onboard Employees</div> */}
                <div className="flex-row justify-between">
                  <div className="empData-Tab-heading">Onboard Employees
                  </div>
                  {/* <EmployeeforDashboardDataOnboarding
                    title={"Onboarding Employees"}
                    subcompany={sessionStorage.getItem('company_id')}
                    month={selectedOption === "this month" ? currentMonth : previousMonth}
                    year={selectedOption === "this month" ? currentYear : previousYear}
                  /> */}
                </div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.joined_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`Inactive Employees in ${selectedOption}`} className="empData-tab width-15vw Color-tab-yellow">
                {/* <div className="empData-Tab-heading">Inactive Employees</div> */}
                <div className="flex-row justify-between">
                  <div className="empData-Tab-heading">Inactive Employees
                  </div>
                  {/* <EmployeesforDashboardData
                    data={customSortByKey(empTableData?.inactive_employees, "emp_code")}
                    title={"Inactive Employees"}
                    subcompany={sessionStorage.getItem('company_id')}
                    empstatus={"inactive"} month={selectedOption === "this month" ? currentMonth : previousMonth}
                    year={selectedOption === "this month" ? currentYear : previousYear}
                  /> */}
                </div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.inactive_employees_count - (empData.resigned_employees_count + empData.terminated_employees_count)}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`Employees Resigned/Terminated in ${selectedOption}`} className="empData-tab width-15vw Color-tab-red">
                {/* <div className="empData-Tab-heading">Separated Employees</div> */}
                <div className="flex-row justify-between">
                  <div className="empData-Tab-heading">Separated Employees
                  </div>
                  {/* <EmployeesforDashboardData
                    data={customSortByKey(empTableData?.inactive_employees, "emp_code")}
                    title={"Separated Employees"}
                    subcompany={sessionStorage.getItem('company_id')}
                    empstatus={"inactive"} month={selectedOption === "this month" ? currentMonth : previousMonth}
                    year={selectedOption === "this month" ? currentYear : previousYear}
                  /> */}
                </div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.resigned_employees_count + empData.terminated_employees_count}</div>
              </div>
            </div>
          </div>

          {/* <div className="empData-dashboard">
            <div>
              <div style={{ cursor: "help" }} title={`Probation in ${selectedOption}`} className="empData-tab width-15vw Color-tab-yellow">
                <div className="empData-Tab-heading">Probation Employees</div>
                <div className="empData-Tab-value font-weight600  font-weight600 ">{empData.probation_employee_count}</div>
              </div>
            </div>
          </div> */}

          {/* <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={LateCOLORS[index % LateCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading font-size-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#F7BA1E" }}
              ></div>
              <div className="piechart-subheading font-weight500    font-size-subheading">Leave</div>
            </div>
            <div className=piechart-percentage  font-weight700">{leavepercentage} %</div>
          </div> */}

          {/* <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataaa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataaa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={PresentCOLORS[index % PresentCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading font-size-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#06AD06" }}
              ></div>
              <div className="piechart-subheading font-weight500    font-size-subheading">Present</div>
            </div>
            <div className=piechart-percentage  font-weight700">{presentpercentage} %</div>
          </div> */}

        </div>
      </div>
    </>
  );
};

export default EmployeeData;
