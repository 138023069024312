
import React, { useState, useEffect, useRef } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer, PieChart, Pie, Cell, } from 'recharts';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../config/axios';
import * as XLSX from "xlsx";
import axios from 'axios';
import { customSortByKey, handleAllError } from '../CustomFunctions';
import Select from "react-select";
import { AddwithBlueCircle } from '../AllSvg';
import { formattedDate } from '../Date';

const Card = ({ number, content, pay, footer, image, svgPath }) => {
  return (
    <div className="summaryCard" >

      <div className="summaryCardd">
        <div>
          <h4 className="summaryCard-number font-size-subheading  ">{number}</h4>
          <p className="summaryCard-text-a">{content}</p>
          <p className="summaryCard-text-b font-size-text ">{pay} Last Month</p>
        </div>
        <div className='summaryCard-button'>
          <button className='summaryCard-buttonn'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#007bff">
              <path d={svgPath} />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const SummaryStats = () => {
  const data = [
    { number: "89,935", content: "Statutory Pay", pay: "+3.4", svgPath: "M19,8V7c0-1.1-0.9-2-2-2H7C5.9,5,5,5.9,5,7v1H3v13c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V8H19z M12,18c-1.1,0-2-0.9-2-2 s0.9-2,2-2s2,0.9,2,2S13.1,18,12,18z M17,9H7V7h10V9z M5,20v-2h14v2H5z M7,12c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S7.55,12,7,12z M17,12c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S17.55,12,17,12z" },
    { number: "23,223.5", content: "Net Salary", pay: "+3.4", svgPath: "M17.2 11h-2.1c-1 0-1.9.4-2.6 1h-.9c-.7-.6-1.7-1-2.7-1H6.8c-1.8 0-3.5 1.2-4 3l-.7 2.6c-.3 1.2.3 2.4 1.4 2.7.6.2 1.2.3 1.9.5.2.8.8 1.4 1.6 1.6 1.6.5 3.3.8 5 .8s3.4-.3 5-.8c.8-.3 1.3-.9 1.5-1.6.7-.1 1.3-.3 1.9-.5.9-.3 1.5-1.2 1.5-2.1 0-.2 0-.5-.1-.6l-.6-2.6c-.5-1.8-2.2-3-4-3zM4 17.1l.7-2.6C5 13.6 5.9 13 6.8 13h1.1c-.1.1-.2.2-.3.2-.1.1-.2.1-.2.2l-.3.3c-.1.2-.1.2-.2.3-.1.1-.2.3-.2.4 0 .1-.1.1-.1.2-.1.2-.2.5-.3.7l-.7 2.4c-.5-.1-.9-.2-1.4-.4-.1 0-.2-.1-.2-.2zm12.4 2.4c-2.9.9-5.9.9-8.8 0-.2-.1-.3-.3-.3-.5l.9-3c.3-1.1 1.4-1.9 2.6-1.9h2.5c1.2 0 2.2.8 2.6 1.9l.9 3v.1c-.1.2-.3.3-.4.4zm3.4-2.1c-.5.2-1 .3-1.4.4l-.7-2.4c-.1-.2-.2-.5-.3-.7 0-.1-.1-.1-.1-.2-.1-.1-.2-.3-.2-.4-.1-.1-.1-.2-.2-.2l-.3-.3c-.1-.1-.2-.1-.2-.2-.1-.1-.2-.2-.3-.2h1.1c.9 0 1.8.6 2 1.5l.7 2.6c.1 0 .1.1-.1.1zM16 3.9c-.4 0-.8.1-1.2.2C14 3.4 13.1 3 12 3c-1.1 0-2.1.4-2.8 1.2-.4-.2-.8-.3-1.2-.3-.9 0-1.8.4-2.3 1-.5.6-.8 1.3-.8 2.1 0 .2 0 .5.1.7.3 1.4 1.6 2.4 3 2.4.4 0 .8-.1 1.2-.2.7.7 1.7 1.1 2.8 1.1 1.1 0 2.1-.4 2.8-1.2.4.2.8.2 1.2.2 1.5 0 2.7-1 3-2.4 0-.2.1-.4.1-.7 0-.8-.3-1.5-.8-2-.5-.6-1.4-1-2.3-1zM7 7.3V7c0-.3.1-.5.3-.7.1-.3.4-.4.7-.4h.1c0 .2-.1.3-.1.5V7c0 .3 0 .6.1.9v.2c-.5 0-1-.3-1.1-.8zM12 9c-.7 0-1.4-.4-1.7-1-.1-.2-.2-.3-.2-.5-.1-.2-.1-.4-.1-.5 0-.4.1-.7.3-1 .1-.1.1-.2.2-.3.4-.5.9-.7 1.5-.7s1.1.2 1.5.7c.1.1.1.2.2.3.2.3.3.6.3 1 0 .1 0 .3-.1.5 0 .2-.1.4-.2.5v.1c-.3.5-1 .9-1.7.9zm5.1-1.8c-.1.5-.7.9-1.2.8v-.2c.1-.2.1-.5.1-.8 0-.4-.1-.7-.1-1.1.4 0 .7.1 1 .4.1.2.2.4.2.7v.2z" },
    { number: "46,827", content: "Deduction", pay: "-0.91%", svgPath: "M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" },
    { number: "124,854", content: "Payroll Cost", pay: "+1.51%", svgPath: "M20 11H7.41l3.29-3.29L10 6l-6 6 6 6 1.71-1.71L7.41 13H20v-2z" }
  ];
  return (
    <>
      <div className='summaryStats'>
        {data.map((item, index) => (
          <Card
            key={index}
            number={item.number}
            content={item.content}
            pay={item.pay}
            svgPath={item.svgPath}
            footer={<div>{item.name} {item.surname}</div>}
            style={{ maxWidth: '400px', marginBottom: '20px' }}
          />
        ))}
      </div>


    </>
  )
};

const PaymentStatus = () => {
  const [data, setData] = useState({
    employees: 2350,
    payment: 56,
    pending: 56,
    paid: 56,
  });
  const [selectedOption, setSelectedOption] = useState('today');
  useEffect(() => {
    // Example API call
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.example.com/payment-status');
        const result = await response.json();
        setData({
          employees: result.employees,
          payment: result.payment,
          pending: result.pending,
          paid: result.paid,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="payment-status">
      <div className="header">
        <div className="title">
          <p className='font-size-text'>Salary Status</p>
          <span style={{ fontSize: "0.75em" }}>{data.employees} Employee</span>
        </div>
        <div className="paymentDashboard-dropdown">
          <select className='paymentDashboard-a font-size-label' onChange={handleChange} value={selectedOption}>
            <option value="today">This Year</option>
            <option value="yesterday">Last Year</option>
          </select>
        </div>
      </div>
      <div className="status-bars">
        <div className="status-bar">
          <div className="fill payment payment-radius" style={{ width: `${data.payment}%` }}></div>
          <div className="fill pending payment-radius-a" style={{ width: `${data.pending}%` }}></div>
          <div className="fill paid payment-radius-b" style={{ width: `${data.paid}%` }}></div>
        </div>
      </div>
      <div className="status-labels">
        <div className="label font-size-label">
          <span className="dot payment"></span> Pending <strong>{data.payment}%</strong>
        </div>
        <div className="label font-size-label">
          <span className="dot pending"></span>Hold<strong>{data.pending}%</strong>
        </div>
        <div className="label font-size-label">
          <span className="dot paid"></span> Paid<strong>{data.paid}%</strong>
        </div>
      </div>
    </div>
  );
};

const CustomLegend = ({ payload }) => (
  <ul className='customLegend' >
    {payload.map((entry, index) => (
      <li className='label font-size-label' key={`item-${index}`} style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
        <span className='dot'
          style={{
            backgroundColor: entry.color,
          }}
        />
        <div  >
          {entry.value}
          <strong style={{}}>50%</strong>
        </div>
      </li>
    ))}
  </ul>
);

const PayrollHistory = ({ year, code }) => {
  const [selectedOption, setSelectedOption] = useState('today');
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const data = [
    { name: 'Jan', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Feb', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Mar', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'April', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'May', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Jun', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'July', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Aug', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Sept', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Oct', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Nov', NetSalary: 15, Taxes: 35, GrossSalary: 25 },
    { name: 'Dec', NetSalary: 15, Taxes: 35, GrossSalary: 25 }
  ];
  return (
    <div className='payrol-history width-50vw' >
      <div className="header">
        <div className="title">
          <p className='font-size-text'>Payroll History</p>
        </div>
        <div className="paymentDashboard-dropdown">
          <select className='paymentDashboard-a font-size-label' onChange={handleChange} value={selectedOption}>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
          </select>
        </div>
      </div>
      <div >
        <ResponsiveContainer height={312}>
          <BarChart data={data} margin={{ top: 10, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray="10 6" vertical={false} />
            <XAxis dataKey="name" angle={-90} tickMargin={10} tick={{ fontSize: 10 }} />
            <YAxis tickMargin={10} tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend content={<CustomLegend />} />
            <Bar dataKey="NetSalary" fill="red" barSize={6} radius={7} />
            <Bar dataKey="Taxes" fill="#FFD700" barSize={6} radius={7} />
            <Bar dataKey="GrossSalary" fill="#6495ED" barSize={6} radius={7} />
          </BarChart>
        </ResponsiveContainer>
      </div>

    </div>
  );
};

const BankDetails = () => {
  const data = [
    { name: 'Category A', value: 30 },
    { name: 'Category B', value: 10 },
  ];
  const dataa = [

    { name: 'Category A', value: 10 },
    { name: 'Category B', value: 30 },
  ];
  const dataaa = [
    { name: 'Category A', value: 40 },
    { name: 'Category B', value: 20 },
  ];

  const empCOLOURS = ["#FF747C", "#EFEFEF"];
  const detCOLOURS = ["#F7BA1E", "#EFEFEF"];
  const pendingCOLORS = ["#06AD06", "#EFEFEF"];

  return (
    <div className="bank-details">
      <div>
        <div className='title'>
          <p className='font-size-text'>Bank Details</p>
        </div>
        <div className="piechart-flex">

          <div className="hr-piechart-dashboard">
            <div>
              <PieChart width={190} height={150}>
                <Pie
                  data={data}
                  cx={90}
                  cy={120}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={70}
                  cornerRadius={20}
                  outerRadius={90}
                  paddingAngle={3}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={empCOLOURS[index % empCOLOURS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="label bank-label">
              <div
                className="dot "
                style={{ backgroundColor: "#FF747C" }}
              ></div>
              <div>Total Employee <strong>56%</strong></div>

            </div>
          </div>


          <div className="hr-piechart-dashboard">
            <div>
              <PieChart width={190} height={150}>
                <Pie
                  data={dataa}
                  cx={90}
                  cy={120}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={70}
                  cornerRadius={20}
                  outerRadius={90}
                  paddingAngle={3}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={detCOLOURS[index % detCOLOURS.length]} />
                  ))}

                </Pie>
              </PieChart>
            </div>
            <div className="label bank-label">
              <div
                className="dot "
                style={{ backgroundColor: "#F7BA1E" }}
              ></div>
              <div>Add Details <strong>56%</strong></div>

            </div>
          </div>



          <div className="hr-piechart-dashboard">
            <div>
              <PieChart width={190} height={150}>
                <Pie
                  data={dataaa}
                  cx={90}
                  cy={120}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={70}
                  cornerRadius={20}
                  outerRadius={90}
                  fill="green"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pendingCOLORS[index % pendingCOLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="label bank-label">
              <div
                className="dot "
                style={{ backgroundColor: "#06AD06" }}
              ></div>
              <div>Pending Details <strong>56%</strong></div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

const PaymentDashboard = () => {
  return (
    <>
      <SummaryStats />
      <div className='paymentDashboard' style={{ marginRight: "18px" }}>
        <div className='payrollHistoryBox'>
          <PayrollHistory />
        </div>
        <div className='paymentDashboard-b'>
          <PaymentStatus />
          <BankDetails />
        </div>
      </div>
    </>
  );
};


export { PaymentDashboard };