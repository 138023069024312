import ReactToPrint from 'react-to-print';
import { BASE_URL, FRONTEND_URL } from "../../../config/axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { formattedDate, formattedDateLong } from '../../Date';
import numberToWords from 'number-to-words';
import { ExpenseDetailApproved, ExpenseDetailPartialApproved, ExpenseDetailReject, BillAuthentication, ViewBill, TLExpenseApprove, TLExpenseReject, AccountAExpenseApprove, AccountAExpenseReject, AdminExpenseApprove, AdminExpenseReject, AccountAPartialEdit } from './ExpenseSlipComponents';





const ExpenseSlip = React.forwardRef((props, ref) => {

  let { id } = useParams();
  const location = useLocation();
  const opener = location.state ? location.state.opener : "";
  const [expenseDetails, setExpenseDetails] = useState([])
  const [expenseData, setExpenseData] = useState([])
  const [personalData, setPersonalData] = useState({})
  const [imprestData, setImprestData] = useState({})
  const [personData, setPersonData] = useState([])


  const totalImprestAsked = parseFloat(expenseDetails.fooding) + parseFloat(expenseDetails.transportation) + parseFloat(expenseDetails.accomodation) +
    // parseFloat(expenseDetails.other);
    (expenseDetails.other_details?.length > 0
      ? expenseDetails.other_details.reduce((total, other) => total + parseFloat(other.amount || 0), 0)
      : 0);
  const totalImprestAskedWords = numberToWords.toWords(totalImprestAsked ? totalImprestAsked : 0);
  const totalImprestApproved = parseFloat(expenseDetails.approved_fooding) + parseFloat(expenseDetails.approved_transportation) + parseFloat(expenseDetails.approved_accomodation) +
    // parseFloat(expenseDetails.approved_other);
    (expenseDetails.other_details?.length > 0
      ? expenseDetails.other_details.reduce((total, other) => total + parseFloat(other.approved_other || 0), 0)
      : 0);
  const totalImprestApprovedWords = numberToWords.toWords(totalImprestApproved ? totalImprestApproved : 0);

  const [expensesum, setExpenseSum] = useState(0)
  const [sum, setSum] = useState(0)
  const [dates, setDates] = useState(0)
  const [amountsApproved, setAmountsApproved] = useState(false)


  const [foodingTotal, setFoodingTotal] = useState(0);
  const [transportationTotal, setTransportationTotal] = useState(0);
  const [accomodationTotal, setAccomodationTotal] = useState(0);
  const [otherTotal, setOtherTotal] = useState(0);


  const date = formattedDate

  const getLetterData = async () => {
    try {
      const exp = await axios.get(`${BASE_URL}/wfm/expensebyid/${id}`);
      setExpenseDetails(exp.data)

      const res = await axios.get(`${BASE_URL}/wfm/expensedetailsbyexpense/${exp.data.id}`);
      setExpenseData(res.data)
      setDates(res.data.map(item => item.date).sort((a, b) => new Date(a) - new Date(b)));
      setAmountsApproved(res.data.some(expense => expense.bill_approved_amt === null))

      const summ = res.data.some(expense => expense.bill_approved_amt === null)
        ? res.data.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
        : res.data.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
      setSum(summ)

      const expsumm = res.data.reduce((acc, item) => acc + parseFloat(item.item_amount), 0);
      setExpenseSum(expsumm)



      const ress = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${exp.data.empcode}/`)
      setPersonalData(ress.data)

      const resss = await axios.get(`${BASE_URL}/wfm/imprestbyid/${exp.data.imprest}/`)
      setImprestData(resss.data)

      setPersonData(resss.data.multiple_person_details.map(person => person.name));

      res.data.forEach(item => {
        const amount = parseFloat(item.item_amount);
        switch (item.expense_type) {
          case 'fooding':
            setFoodingTotal(prevTotal => prevTotal + amount);
            break;
          case 'transportation':
            setTransportationTotal(prevTotal => prevTotal + amount);
            break;
          case 'accomodation':
            setAccomodationTotal(prevTotal => prevTotal + amount);
            break;
          // case 'other':
          //   setOtherTotal(prevTotal => prevTotal + amount);
          //   break;
          default:
            setOtherTotal(prevTotal => prevTotal + amount);
            break;
        }
      });

    } catch (err) {
    }
  };

  useEffect(() => {
    getLetterData();

  }, []);

  console.log("expenseData");
  console.log(expenseData);



  return (
    <div className='printSlip-container' ref={ref}>
      <div className='printSlip'>
        <div className='printSlip-header'>
          <div className='printSlip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='printSlip-logo' />

            <div className='printSlip-header-left-text  font-size-heading'>
              Expense Slip For : "{expenseDetails.name}"
            </div>
          </div>
          <div className='printSlip-header-right'>
            <div className='printSlip-header-right-top  font-weight600   font-size-heading'>
              Civilmantra infracon private limited
            </div>
            <div className='printSlip-header-right-bottom  font-weight400  font-size-subheading'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        <div className='printSlip-hr'></div>
        <div className='printSlip-header-second'>
          <div className='printSlip-header-second-section1'>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>Project Name</div>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>Employee Code</div>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>Department</div>
          </div>
          <div className='printSlip-header-second-section2'>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{expenseDetails.project_name}</div>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{expenseDetails.empcode}</div>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{personalData.department_name}</div>
          </div>
          <div className='printSlip-header-second-section3'>
          </div>
          <div className='printSlip-header-second-section4'>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>Name</div>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>Start Date</div>
            <div className='printSlip-heading-black   font-weight600 font-size-subheading'>End Date</div>
          </div>
          <div className='printSlip-header-second-section5'>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{personalData.name}</div>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{dates.length == 0 ? "Expense Details Pending " : formattedDateLong(dates[0])}</div>
            <div className='printSlip-heading-black1  font-weight400  font-size-subheading'>{dates.length == 0 ? "Expense Details Pending " : formattedDateLong(dates[dates.length - 1])}</div>
          </div>
        </div>

        <div className='printSlip-body'>
          <tr>
            <th className='printSlip-heading-blue font-weight500 font-size-subheading'>Imprest: </th>
            {/* <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalImprestAskedWords}</td> */}
          </tr>

          <table className='printSlip-table'>
            <thead>
              <tr className='printSlip-head'>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'></th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Fooding</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Traveling</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Accomodation</th>
                {imprestData.other_details?.length > 0
                  ?
                  <>
                    {imprestData.other_details.map((other, index) => (
                      <th style={{ padding: "0px 5px" }} className='printSlip-table-td form-text-trasformation-uppercase'>{other.other_name ? other.other_name : `Other-${index + 1}`}</th>
                    ))}
                  </>
                  : null}
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr>
                  <td className='printSlip-heading-blue font-weight500 font-size-subheading'>Asked</td>
                  <td className='printSlip-table-td'>{imprestData.fooding}</td>
                  <td className='printSlip-table-td'>{imprestData.transportation}</td>
                  <td className='printSlip-table-td'>{imprestData.accomodation}</td>
                  {/* <td className='printSlip-table-td'>{imprestData.other}</td> */}
                  {imprestData.other_details?.length > 0
                    ?
                    imprestData.other_details?.map((other, index) => (
                      <td className='printSlip-table-td'>{other.amount || 0}</td>
                    ))
                    : null}
                  <td className='printSlip-table-td '>{totalImprestAsked}</td>
                </tr>
                {/* <tr>
                  <td colSpan={6} className='printSlip-hrr'></td>
                </tr> */}
                <tr>
                  <td className='printSlip-heading-blue font-weight500 font-size-subheading'>Approved</td>
                  <td className='printSlip-table-td'>{imprestData.approved_fooding}</td>
                  <td className='printSlip-table-td'>{imprestData.approved_transportation}</td>
                  <td className='printSlip-table-td'>{imprestData.approved_accomodation}</td>
                  {/* <td className='printSlip-table-td'>{imprestData.approved_other}</td> */}
                  {imprestData.other_details?.length > 0
                    ?
                    imprestData.other_details?.map((other, index) => (
                      <td className='printSlip-table-td'>{other.approved_other || 0}</td>
                    ))
                    : null}
                  <td className='printSlip-table-td'>{totalImprestApproved}</td>
                </tr>

              </React.Fragment>

              <tr>
                <td colSpan={65} className='printSlip-hrr'></td>
              </tr>

            </tbody>
          </table>
          {/* <br /> */}
          <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <th>Grand Total</th>
              <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalImprestApproved}</td>
            </div>
            <div>
              <th>IN WORDS</th>
              <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalImprestApprovedWords}</td>
            </div>
          </tr>
          <br />
          <table className='printSlip-table'>
            <thead>
              <tr>
                <th className='printSlip-heading-blue font-weight500 font-size-subheading'>Expense: </th>
                {/* <th>Expense Asked : </th> */}
                {/* <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalImprestApprovedWords}</td> */}
              </tr>
              <tr className='printSlip-head'>
                <th style={{ padding: "0px 5px" }} >Employee name</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Fooding</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Traveling</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Accomodation</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Others</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr>
                  {/* <td className='printSlip-heading-blue font-weight500 font-size-subheading' style={{ width: '200px' }}>{personData}</td> */}
                  <td className='printSlip-heading-blue font-weight500 font-size-subheading' style={{ width: '200px' }}>
                    {personData.map((name, index) => (
                      <React.Fragment key={index}>
                        {name},
                        {index !== personData.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </td>
                  <td className='printSlip-table-td'>{foodingTotal}</td>
                  <td className='printSlip-table-td'>{transportationTotal}</td>
                  <td className='printSlip-table-td'>{accomodationTotal}</td>
                  <td className='printSlip-table-td'
                    title={expenseData
                      .filter(other => !['fooding', 'transportation', 'accomodation'].includes(other.expense_type))
                      .map((other, index) => `${other.item_name} (${other.expense_type}) : ${other.item_amount}`)
                      .join('\n')
                    }
                  >{otherTotal}</td>
                  <td className='printSlip-table-td'>{expensesum}</td>
                </tr>
              </React.Fragment>
              <br />

              <tr>
                <td colSpan={65} className='printSlip-hrr'></td>
              </tr>
            </tbody>
          </table>
          <table className='printSlip-table'>

            <thead style={{ margin: "20px 0px" }}>
              <tr className='printSlip-head' >
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Sr No.</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Date</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Name</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Amount</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Approved Amt.</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Expense Type</th>
                <th style={{ padding: "0px 5px" }} className='printSlip-table-td'>Bills</th>
              </tr>
            </thead>

            <tbody>

              {expenseData
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((j, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr>
                      <td className='printSlip-table-td'>{index + 1}</td>
                      <td className='printSlip-table-td'>{formattedDateLong(j.date)}</td>
                      <td className='printSlip-table-td'>{j.item_name}</td>
                      <td className='printSlip-table-td'>{j.item_amount}</td>
                      <td className='printSlip-table-td align-center ' title={`${j.bill_amount_change_reason ? `Amount Change Reason: \n${j.bill_amount_change_reason}` : ""}`}>
                        {j.bill_approved_amt ?
                          j.bill_approved_amt === "0" && j.bill_approved === "rejected" ?
                            <span title={`${j.bill_amount_change_reason ? `Amount Change Reason: \n${j.bill_amount_change_reason}\n` : ""}${j.bill_approved === "rejected" ? `Bill Reject Reason: \n${j.bill_rejection_reason}` : ""}`} style={{ textDecoration: 'line-through' }}>{j.tl_approved_amt}</span>
                            : j.bill_approved_amt
                          :
                          opener === "tl" && expenseDetails.rh_status === "pending" ?
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                              <ExpenseDetailApproved i={j} getExpensedetail={getLetterData} />
                              <ExpenseDetailPartialApproved i={j} getExpensedetail={getLetterData} />
                              {/* <ExpenseDetailReject i={j} getExpenseDetail={getLetterData} /> */}
                            </div>
                            :
                            "-"
                        }</td>
                      <td className='printSlip-table-td'>{j.expense_type}</td>
                      <td className='printSlip-table-td flex-row'>
                        {j.bill ?
                          opener === "acc" && expenseDetails.account_status_a === "pending" ?
                            <BillAuthentication i={j} getExpenseDetail={getLetterData} />
                            :
                            <>
                              <ViewBill i={j} />
                              {expenseDetails.account_status_a !== "pending" ?
                                j.bill_approved !== "pending" ?
                                  j.bill_approved === "approved" ?
                                    <span title='Bill Approved' style={{ marginLeft: '8px' }}>✅</span>
                                    : <span title={`Bill Rejection Reason : ${j.bill_rejection_reason}`} style={{ marginLeft: '8px' }}>❌</span>
                                  : <input
                                    type="checkbox"
                                    checked={false}
                                    readOnly
                                    className="form-checkbox"
                                  />
                                : null

                              }
                            </>

                          : " No bill"}
                      </td>

                      {/* {
                        opener !== "admin" ?
                          <td className='flex-row'>
                            {opener !== "acc" ?
                              <>
                                <ExpenseDetailApproved i={j} getExpensedetail={getLetterData} />
                                <ExpenseDetailPartialApproved i={j} getExpensedetail={getLetterData} />
                                <ExpenseDetailReject i={j} getExpenseDetail={getLetterData} />
                              </>
                              :
                              <BillAuthentication i={j} getExpenseDetail={getLetterData} />
                            }
                          </td>
                          : null
                      } */}
                      <td>

                      </td>

                    </tr>
                  </React.Fragment>
                ))}
              <tr>
                <td colSpan={8} className='printSlip-hrr'></td>
              </tr>
            </tbody>
          </table>

          <tr>
            <th >TOTAL </th>
            <td className='printSlip-table-td'>{sum}</td>
          </tr>
          <tr>
            <th className='printSlip-heading-blue font-weight500 font-size-subheading'>IN WORDS</th>
            <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{numberToWords.toWords(sum)}</td>
          </tr>

        </div>
        <div className='printSlip-hr'></div>
        <h6 style={{ fontSize: '10px' }}><b>*This is Software generated Expense Slip no signature required*</b></h6>
      </div>
      <div className='printSlip-bg'>
      </div>
    </div>
  )
});


const ExpenseSlipByRequest = () => {
  let { id } = useParams();
  const location = useLocation();
  const opener = location.state ? location.state.opener : "";
  const ref = useRef();
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  let backUrl = "/" + urlParts[3] + `/wfm`;
  let accfirsttoggle = 2;
  let firsttoggle = 1;
  let secondtoggle = 1;
  console.log("location")
  console.log(location)
  console.log(location.state)
  if (opener === "tl") {
    firsttoggle = 4;
    secondtoggle = 5;
    backUrl = "/" + urlParts[3] + `/teamRequestManagement`;
  }
  else if (opener === "admin") {
    firsttoggle = 5;
    // secondtoggle = 6;
    secondtoggle = 2;
    backUrl = "/" + urlParts[3] + `/adminRequestManagement`;
  }
  else if (opener === "acc") {
    accfirsttoggle = 2;
    backUrl = "/" + urlParts[3] + `/imprestExpenseRequestManagement`;
  }
  else if (opener === "accb") {
    accfirsttoggle = 3;
    backUrl = "/" + urlParts[3] + `/imprestExpenseRequestManagement`;
  }
  const backroute = (firsttoggle, secondtoggle, accfirsttoggle) => {
    navigate(backUrl, { state: { firsttoggle, secondtoggle, accfirsttoggle } })
  }

  const [expenseDetails, setExpenseDetails] = useState([])
  const [expenseDataDetails, setExpenseDataDetails] = useState([])

  const [amountsApproved, setAmountsApproved] = useState(false)
  const [billAuthenticated, setBillAuthenticated] = useState(false)

  const [buffer, setBuffering] = useState(true); //buffering logic

  const getExpenseData = async () => {
    setBuffering(true); // Start Buffering
    try {
      const exp = await axios.get(`${BASE_URL}/wfm/expensebyid/${id}`);
      setExpenseDetails(exp.data)

      const expdeat = await axios.get(`${BASE_URL}/wfm/expensedetailsbyexpense/${exp.data.id}`);
      setExpenseDataDetails(expdeat.data)
      setAmountsApproved(expdeat.data.some(expense => expense.bill_approved_amt === null))
      setBillAuthenticated(expdeat.data.some(expense => expense.bill_approved === "pending"))
    } catch (err) {
    } finally {
      setBuffering(false); // End Buffering
    }
  };
  useEffect(() => {
    getExpenseData();
  }, []);
  return (

    <div className='flex-column flex-center slip-container'>
      <div className='printSlip-width flex-row justify-evenly button-margin'>
        {/* <div className='printSlip-width flex-row justify-evenly'> */}
        <div></div>
        <button className="model-button  font-weight500 model-button-cancel font-size-heading" onClick={(e) => { backroute(firsttoggle, secondtoggle, accfirsttoggle) }}>Back</button>
        <div></div>
        <ReactToPrint
          bodyClass="print-agreement"
          content={() => ref.current}
          trigger={() => (
            <button className='model-button model-button-black'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
              </svg> Print
            </button>
          )}
        />
      </div>

      {buffer ? <div className="spinner"></div>
        :
        <>
          {opener === "tl" && expenseDetails.rh_status === "pending" ?
            amountsApproved ?
              "Please Approve All Bill Amounts First"
              :
              <div className='printSlip-width flex-row justify-evenly' >
                <TLExpenseReject i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
                <TLExpenseApprove i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
              </div>
            : <span request-status={expenseDetails.rh_status} className="request-status align-center">Already Performed Action : TL {expenseDetails.rh_status}</span>
          }
          <br />
          {opener === "acc" && expenseDetails.account_status_a === "pending" ?

            // <div className='printSlip-width flex-row justify-evenly' >
            //   <AccountAExpenseReject i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
            //   <AccountAPartialEdit i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
            //   {billAuthenticated ?
            //     "For Approval Please Complete All  Bill Authentication First"
            //     :
            //     <AccountAExpenseApprove i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />}
            // </div>
            <>
              <div className='printSlip-width flex-row justify-evenly' >
                <AccountAExpenseReject i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
                <AccountAPartialEdit i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} backUrl={backUrl} accfirsttoggle={accfirsttoggle} />
                {billAuthenticated ?
                  null
                  :
                  <AccountAExpenseApprove i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />}
              </div>
              <br />
              {billAuthenticated ?
                "For Approval Please Complete All  Bill Authentication First"
                :
                null}
            </>
            : expenseDetails.rh_status !== "pending" ? <span request-status={expenseDetails.account_status_a} className="request-status align-center">Already Performed Action : Bills by Accounts {expenseDetails.account_status_a}</span> : null
          }
          <br />
          {opener === "admin" && expenseDetails.admin_status === "pending" ?

            <div className='printSlip-width flex-row justify-evenly' >
              <AdminExpenseReject i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
              <AdminExpenseApprove i={expenseDetails} j={expenseDataDetails} getExpenseData={getExpenseData} />
            </div>
            : expenseDetails.account_status_a !== "pending" ?
              <span request-status={expenseDetails.admin_status} className="request-status align-center">Already Performed Action : Admin {expenseDetails.admin_status}</span> : null
          }
        </>
      }
      <ExpenseSlip ref={ref} />

    </div>
  );
};


export default ExpenseSlipByRequest