import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { handleAllError } from "../CustomFunctions";
import { BASE_URL } from "../../config/axios";
import { AddwithBlueCircle, AddwithWhiteCircle, DropdownArrow, DropdownArrowOption } from "../AllSvg";
import { formatDateTime, formattedDate } from "../Date";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import { DocumentSection } from "../FormdataComponent";


const RaiseATicket = ({ getTicketList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        assign_date: formattedDate,
        due_date: "",
        assigned_by_name: sessionStorage.getItem("name") || "",
        assigned_by: sessionStorage.getItem("email") || "",
        status: "pending",
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title", "description", "assign_date", "due_date", "assigned_by_name", "assigned_by"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/ticket/ticket/`,
                    formData,
                );

                if (res.status === 200) {
                    await getTicketList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                handleAllError(err, loadingToastId)
                console.log(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    return (
        <>
            <button title="Raise a New Request" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle /> New Ticket
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Raise a Ticket Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div title="Title" className="flex-column">
                                <label htmlFor="title" className="form-labels font-weight500 font-size-heading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    id="title"
                                    type="text"
                                    name="title"
                                    maxLength={300}
                                    placeholder="Enter Title"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                                />
                                {errors.title && <span className="error-message">{errors.title}</span>}
                            </div>

                            <div title="Description" className="flex-column">
                                <label htmlFor="description" className="form-labels font-weight500 font-size-heading">
                                    Description<span className="required">*</span>
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    placeholder="Enter Description"
                                    onChange={handleInputChange}
                                    value={formData.description}
                                    className={`form-input-textarea ${errors.description ? "error" : inputState.description ? "success" : ""}`}
                                />
                                {errors.description && <span className="error-message">{errors.description}</span>}
                            </div>

                            <div title="Due Date" className="flex-column">
                                <label htmlFor="due_date" className="form-labels font-weight500 font-size-heading">
                                    Due Date<span className="required">*</span>
                                </label>
                                <input
                                    id="due_date"
                                    type="date"
                                    name="due_date"
                                    onChange={handleInputChange}
                                    value={formData.due_date}
                                    className={`form-input ${errors.due_date ? "error" : inputState.due_date ? "success" : ""}`}
                                />
                                {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                            </div>

                            <div title="Assigned By Name" className="flex-column">
                                <label htmlFor="assigned_by_name" className="form-labels font-weight500 font-size-heading">
                                    Customer Name<span className="required">*</span>
                                </label>
                                <input
                                    id="assigned_by_name"
                                    type="text"
                                    name="assigned_by_name"
                                    maxLength={150}
                                    placeholder="Enter Assigned By Name"
                                    onChange={handleInputChange}
                                    value={formData.assigned_by_name}
                                    className={`form-input ${errors.assigned_by_name ? "error" : inputState.assigned_by_name ? "success" : ""}`}
                                />
                                {errors.assigned_by_name && <span className="error-message">{errors.assigned_by_name}</span>}
                            </div>

                            <div title="Assigned By" className="flex-column">
                                <label htmlFor="assigned_by" className="form-labels font-weight500 font-size-heading">
                                    Customer Email<span className="required">*</span>
                                </label>
                                <input
                                    id="assigned_by"
                                    type="email"
                                    name="assigned_by"
                                    placeholder="Enter Email"
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                    }}
                                    value={formData.assigned_by}
                                    className={`form-input ${errors.assigned_by ? "error" : inputState.assigned_by ? "success" : ""}`}
                                />
                                {errors.assigned_by && <span className="error-message">{errors.assigned_by}</span>}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel font-weight500" type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Raise Request
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const RaiseTicketWithDocument = ({ getTicketList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            title: "",
            description: "",
            assign_date: formattedDate,
            due_date: "",
            assigned_by_name: sessionStorage.getItem("name") || "",
            assigned_by: sessionStorage.getItem("email") || "",
            status: "pending",
            documents: [{ document_name: "", document_file: "" }],
        })
        setFileNames([]);
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const [fileNames, setFileNames] = useState([]);

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        assign_date: formattedDate,
        due_date: "",
        assigned_by_name: sessionStorage.getItem("name") || "",
        assigned_by: sessionStorage.getItem("email") || "",
        status: "pending",
        documents: [{ document_name: "", document_file: "" }],
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title", "description", "assign_date", "due_date", "assigned_by_name", "assigned_by"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/ticket/document-bulk-with-ticket/`,
                    formData,
                );

                if (res.status === 200) {
                    await getTicketList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                handleAllError(err, loadingToastId)
                console.log(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    }

    const handleDocInputChange = (index, e) => {
        const { name, value, files } = e.target;

        setFormData((prevFormData) => {
            const newDocuments = [...prevFormData.documents];
            newDocuments[index] = {
                ...newDocuments[index],
                [name]: files ? files[0] : value, // Removed .trim()
            };

            if (files) {
                setFileNames((prevFileNames) => {
                    const newFileNames = [...prevFileNames];
                    newFileNames[index] = files[0]?.name || "";
                    return newFileNames;
                });
            }

            return { ...prevFormData, documents: newDocuments };
        });
    };

    const handleAddDocuments = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            documents: [
                ...prevFormData.documents,
                { document_name: "", document_file: null },
            ],
        }));
    };

    const handleRemoveDocument = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            documents: prevFormData.documents.filter((_, i) => i !== index),
        }));
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, i) => i !== index)
        );
    };

    console.log("formData")
    console.log(formData)

    return (
        <>
            <button title="Raise a New Request" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle /> New Ticket
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="half-modal width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Raise a Ticket Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div title="Title" className="flex-column">
                                <label htmlFor="title" className="form-labels font-weight500 font-size-heading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    id="title"
                                    type="text"
                                    name="title"
                                    maxLength={300}
                                    placeholder="Enter Title"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                                />
                                {errors.title && <span className="error-message">{errors.title}</span>}
                            </div>

                            <div title="Description" className="flex-column">
                                <label htmlFor="description" className="form-labels font-weight500 font-size-heading">
                                    Description<span className="required">*</span>
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    placeholder="Enter Description"
                                    onChange={handleInputChange}
                                    value={formData.description}
                                    className={`form-input-textarea ${errors.description ? "error" : inputState.description ? "success" : ""}`}
                                />
                                {errors.description && <span className="error-message">{errors.description}</span>}
                            </div>

                            <div title="Due Date" className="flex-column">
                                <label htmlFor="due_date" className="form-labels font-weight500 font-size-heading">
                                    Due Date<span className="required">*</span>
                                </label>
                                <input
                                    id="due_date"
                                    type="date"
                                    name="due_date"
                                    onChange={handleInputChange}
                                    value={formData.due_date}
                                    className={`form-input ${errors.due_date ? "error" : inputState.due_date ? "success" : ""}`}
                                />
                                {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                            </div>

                            <div title="Assigned By Name" className="flex-column">
                                <label htmlFor="assigned_by_name" className="form-labels font-weight500 font-size-heading">
                                    Customer Name<span className="required">*</span>
                                </label>
                                <input
                                    id="assigned_by_name"
                                    type="text"
                                    name="assigned_by_name"
                                    maxLength={150}
                                    placeholder="Enter Assigned By Name"
                                    onChange={handleInputChange}
                                    value={formData.assigned_by_name}
                                    className={`form-input ${errors.assigned_by_name ? "error" : inputState.assigned_by_name ? "success" : ""}`}
                                />
                                {errors.assigned_by_name && <span className="error-message">{errors.assigned_by_name}</span>}
                            </div>

                            <div title="Assigned By" className="flex-column">
                                <label htmlFor="assigned_by" className="form-labels font-weight500 font-size-heading">
                                    Customer Email<span className="required">*</span>
                                </label>
                                <input
                                    id="assigned_by"
                                    type="email"
                                    name="assigned_by"
                                    placeholder="Enter Email"
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                    }}
                                    value={formData.assigned_by}
                                    className={`form-input ${errors.assigned_by ? "error" : inputState.assigned_by ? "success" : ""}`}
                                />
                                {errors.assigned_by && <span className="error-message">{errors.assigned_by}</span>}
                            </div>

                            <DocumentSection
                                formData={formData}
                                handleDocInputChange={handleDocInputChange}
                                handleAddDocuments={handleAddDocuments}
                                handleRemoveDocument={handleRemoveDocument}
                                errors={errors}
                                fileNames={fileNames}
                                inputState={inputState}
                            />

                            <div className="button-models">
                                <button className="model-button model-button-cancel font-weight500" type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Raise Request
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const TicketChatboxModal = ({ i }) => {
    const [chatShow, setChatShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChatOpen = (id) => {
        getTicketChat();
        setChatShow(true);
    };

    const handleChatClose = () => {
        setChatShow(false);
    };

    const [formData, setFormData] = useState({
        ticket: i,
        sender: sessionStorage.getItem("email") || "",
        sender_name: sessionStorage.getItem("name") || "",
        message: "",
        timestamp: new Date().toISOString(),
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };
    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["message"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        console.log("newErrors")
        console.log(newErrors)

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSendTaskRemark = async (e) => {
        e.preventDefault();

        console.log("formData")
        console.log(formData)

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");
            try {

                let res = await axios.post(`${BASE_URL}/ticket/ticketChat/`, formData);

                if (res.status === 200) {
                    await getTicketChat();
                    setFormData({
                        ...formData,
                        message: "",
                    });

                    // setChatShow(false);
                    // refreshData();
                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                handleAllError(err, loadingToastId)
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    // !########################################################################



    const [ticketChat, setTicketChat] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const getTicketChat = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/ticket/ticketChat-by-ticket/${i}/`
            );
            setTicketChat(res.data);

        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    return (
        <>
            <button onClick={() => handleChatOpen(i)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 64 64"
                    id="chat-box"
                    fill="none"
                >
                    <path
                        fill="#2576BC"
                        d="M40,54.78a3,3,0,0,1-1.89-.68l-7.73-6.33a1,1,0,1,1,1.26-1.54l7.74,6.32A1,1,0,0,0,41,51.78V47a1,1,0,0,1,1-1,5.07,5.07,0,0,0,5-5V25a4.94,4.94,0,0,0-4.86-5H10.86A4.94,4.94,0,0,0,6,25V41a4.94,4.94,0,0,0,4.86,5H25.52a1,1,0,0,1,0,2H10.86A6.94,6.94,0,0,1,4,41V25a6.94,6.94,0,0,1,6.86-7H42.14A6.94,6.94,0,0,1,49,25V41a7,7,0,0,1-6,6.93v3.85a3,3,0,0,1-1.72,2.71A2.93,2.93,0,0,1,40,54.78ZM53.14,38a1,1,0,0,1,0-2A4.94,4.94,0,0,0,58,31V15a4.94,4.94,0,0,0-4.86-5H21.87a4.88,4.88,0,0,0-4.8,4.16,1,1,0,1,1-2-.32A6.88,6.88,0,0,1,21.87,8H53.14A6.94,6.94,0,0,1,60,15V31A6.94,6.94,0,0,1,53.14,38ZM37,35a2,2,0,1,1,2-2A2,2,0,0,1,37,35ZM26,35a2,2,0,1,1,2-2A2,2,0,0,1,26,35ZM15,35a2,2,0,1,1,2-2A2,2,0,0,1,15,35Z"
                    ></path>
                </svg>
            </button>


            <Modal
                show={chatShow}
                onHide={handleChatClose}
                dialogClassName="request-leave "
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ticket Chat History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column single-day-date">
                        <ul className="chatbox">
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                ticketChat.length === 0 ?
                                    <div className='align-center justify-center ' style={{ alignSelf: 'center' }}>
                                        - No Chat Historys 🪹 -
                                    </div>
                                    :
                                    <>
                                        {ticketChat
                                            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                                            .map((i, index) => (
                                                <>
                                                    {i.sender == sessionStorage.getItem("email") ?
                                                        <>
                                                            <li className="chat chat-reply">
                                                                <p>{i.message}</p>
                                                            </li>
                                                            <p className="chat-reply-timestamp font-size-label">{formatDateTime(i.timestamp).full}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <li className="chat chat-incoming">
                                                                <p>{i.message}</p>
                                                            </li>
                                                            <p className="chat-incoming-timestamp font-size-label">{formatDateTime(i.timestamp).full}</p>
                                                        </>
                                                    }
                                                </>
                                            ))}

                                    </>
                            )
                            }
                        </ul>
                        <div>

                            <div className="chat-input">
                                <textarea
                                    id="message"
                                    name="message"
                                    maxLength={500}
                                    placeholder="Enter Message"
                                    onChange={handleInputChange}
                                    value={formData.message}
                                    className={`${errors.message
                                        ? "error"
                                        : inputState.message
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {/* <ReactQuill
                                id="message"
                                name="message"
                                maxLength={500}
                                placeholder="Enter Message"
                                value={formData.message}
                                onChange={handleInputChange}
                                // className="form-input-quill"
                                className={`${errors.message
                                    ? "error"
                                    : inputState.message
                                        ? "success"
                                        : ""
                                    }`}
                            /> */}

                                <button
                                    onClick={handleSendTaskRemark}
                                    disabled={loading}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        enable-background="new 0 0 32 32"
                                        viewBox="0 0 32 32"
                                        id="send"
                                        width="20"
                                        height="20"
                                    >
                                        <path fill="#2576BC" d="M30.5,14.58997l-28-10c-0.54999-0.19995-1.16998-0.04999-1.58002,0.37C0.51001,5.38,0.39001,6.01001,0.60999,6.56l2.91882,7.31079C3.68054,14.25073,4.0484,14.5,4.45752,14.5h7.70245c0.83002,0,1.5,0.66998,1.5,1.5c0,0.82996-0.66998,1.5-1.5,1.5H4.45752c-0.40912,0-0.77698,0.24921-0.92871,0.62921L0.60999,25.44c-0.21997,0.54999-0.09998,1.17999,0.31,1.59998C1.21002,27.33997,1.59998,27.5,2,27.5c0.16998,0,0.34003-0.03003,0.5-0.09003l28-10c0.59998-0.20996,1-0.77997,1-1.40997S31.09998,14.79999,30.5,14.58997z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {errors.message && (
                        <span className="error-message font-size-text ">
                            {errors.message}
                        </span>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};


export {
    RaiseATicket,
    RaiseTicketWithDocument,
    TicketChatboxModal,
};
